import { Component, OnInit } from '@angular/core';
import { Licencia } from 'src/app/entities/licencia';
import { Empresa } from 'src/app/entities/empresa';
import { Plan } from 'src/app/entities/plan';
import { EmpresasService } from 'src/app/services/empresas.service';
import { PlanesService } from 'src/app/services/planes.service';
import { LicenciasService } from 'src/app/services/licencias.service';
import { ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';
declare var jQuery;

@Component({
    selector: 'app-licencias-cuenta',
    templateUrl: './licencias-cuenta.component.html',
    styleUrls: ['./licencias-cuenta.component.scss']
})
export class LicenciasCuentaComponent implements OnInit {

    licencias: Array<Licencia>;
    empresas: Array<Empresa>;
    planes: Array<Plan>;
    page: number;

    constructor(
        private empresaService: EmpresasService,
        private planesService: PlanesService,
        private licenciasService: LicenciasService,
        private currentRoute: ActivatedRoute
    ) {
        this.empresas = [];
        this.planes = [];
        this.licencias = [];
        this.page = 1;

        
    }

    ngOnInit() {
        // accedemos la informacion del router parent
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
        
        this.loadEmpresas();
        this.loadPlanes();
    }

    /**
     * Permite cargar las licencias
     */
    private load(_id: string) {
        this.licenciasService.getByCuenta(_id)
            .subscribe((response: Array<Licencia>) => {
                this.licencias = response;
            })
    }

    /**
     * Permite cargar las emrpesas relacionada con cada licencia
     */
    private loadEmpresas() {
        this.empresaService.getAll()
            .subscribe((response: Array<Empresa>) => {
                this.empresas = response;
            });
    }

    /**
     * Permite cargar los planes
     */
    private loadPlanes() {
        this.planesService.getAll()
            .subscribe((response: Array<Plan>) => {
                this.planes = response;
            });
    }


    /**
     * Permite eliminar un plan registrado
     * @param _id 
     */
    public remove(_id: string, index: number) {
        Swal({
            type: 'warning',
            title: 'Desea eliminar la licencia seleccionada',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(succ => {
            if (succ.value) {
                jQuery.fancybox.showLoading();
                this.licenciasService.remove(_id)
                    .subscribe(() => {
                        this.licencias.splice(index, 1);
                        jQuery.fancybox.hideLoading();
                    });
            } 
        });
    }


    /**
     * Permite obtener el nombre de una empresa por su id
     * @param _id 
     */
    public getLabelEmpresa(_id: string){
        for(const empresa of this.empresas){
            if(empresa._id === _id){
                return empresa.razon_social;
            }
        }

        return null;
    }

    /**
     * Permite obtener el nombre de una empresa por su id
     * @param _id 
     */
    public getLabelPlan(_id: string){
        for(const plan of this.planes){
            if(plan._id === _id){
                return plan.nombre;
            }
        }
        return null;
    }

}
