import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConexionBd } from '../entities/conexion-bd';

@Injectable({
    providedIn: 'root'
})
export class ConexionesBdService {

    constructor(private http: HttpClient) {

    }

    /**
     * Permite obtener un registro
     * @param _id 
     */
    public getByEmpresa(_id: string) {
        return this.http.get<ConexionBd>(environment.apiUrl.admin + '/business/' + _id + '/connections-bd');
    }

    /**
     * Permite guardar los datos de un registro
     * @param formData 
     */
    public save(formData: ConexionBd) {

        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }

        return this.edit(formData);
    }

    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData 
     */
    public add(formData: ConexionBd, createdBd: boolean = false) {
        return this.http.post(environment.apiUrl.admin + '/connections-bd', {
            "conexionBd" : formData,
            "createBd" : createdBd
        });
    }

    /**
     * Permite guardar los cambios de un registro
     * @param formData 
     */
    public edit(formData: ConexionBd) {
        return this.http.put(environment.apiUrl.admin + '/connections-bd/' + formData._id, formData);
    }

    /**
     * Permite eliminar un plan
     * @param _id 
     */
    public remove(_id: string) {
        return this.http.delete(environment.apiUrl.admin + '/connections-bd/' + _id);
    }

}
