<div class="content-wrapper">

    <form class="form" (ngSubmit)="save()" #formPlanes="ngForm">

        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">
                    Planes
                </h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a routerLink="/dashboard">
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item">
                                <a routerLink="/planes">
                                    Planes
                                </a>
                            </li>
                            <li class="breadcrumb-item active">
                                Formulario Planes
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-4 col-12">
                <div class="btn-group float-md-right">
                    <a class="btn btn-light mr-1" routerLink="/planes">
                        <i class="ft-x"></i> Cancelar
                    </a>
                    <button [disabled]="!formPlanes.valid" type="submit" class="btn btn-success">
                        <i class="fa fa-check-square-o"></i> Guardar
                    </button>
                </div>
            </div>
        </div>

        <div class="content-body">
            <!-- Basic form layout section start -->
            <section>
                <div class="row ">
                    <div class="col-md-6 col-md-offset-3" style="margin: 0 auto;">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Información del Plan</h4>
                                <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                                <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                        <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                        <li><a data-action="close"><i class="ft-x"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body">

                                    <div class="card-text">
                                        <p>
                                            Diligencie el siguiente formulario con la información del plan
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label for="nombre_plan">
                                            Nombre
                                        </label>
                                        <div class="position-relative has-icon-left">
                                            <input required #fieldNombrePlan="ngModel" type="text" id="nombre_plan" class="form-control" [(ngModel)]="formPlan.nombre" placeholder="Ingrese aqui el nombre del plan" name="nombre_plan">
                                            <div class="form-control-position">
                                                <i class="fa fa-briefcase"></i>
                                            </div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldNombrePlan.invalid && (fieldNombrePlan.dirty || fieldNombrePlan.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldNombrePlan.errors.required">
                                                Campo Requerido
                                            </small>
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label for="precio_plan">Precio</label>
                                        <div class="position-relative has-icon-left">
                                            <input required #fieldPrecioPlan="ngModel" type="number" id="precio_plan" class="form-control" [(ngModel)]="formPlan.precio" placeholder="Ingrese aqui el precio del plan" name="precio_plan">
                                            <div class="form-control-position">
                                                <i class="fa fa-money" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldPrecioPlan.invalid && (fieldPrecioPlan.dirty || fieldPrecioPlan.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldPrecioPlan.errors.required">
                                                Campo Requerido
                                            </small>
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label for="plan_dias_prueba">Dias de Prueba</label>
                                        <div class="position-relative has-icon-left">
                                            <input required #fieldDiasPrueba="ngModel" type="number" id="plan_dias_prueba" class="form-control" [(ngModel)]="formPlan.dias_prueba" name="plan_dias_prueba" placeholder="Ingrese aqui el número de dias a dar de prueba en el plan">
                                            <div class="form-control-position">
                                                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldDiasPrueba.invalid && (fieldDiasPrueba.dirty || fieldDiasPrueba.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldDiasPrueba.errors.required">
                                                Campo Requerido
                                            </small>
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label for="plan_dias_vigencia">Dias de Vigencia</label>
                                        <div class="position-relative has-icon-left">
                                            <input required #fieldDiasVigencia="ngModel" type="number" id="plan_dias_vigencia" class="form-control" [(ngModel)]="formPlan.dias_vigencia" name="plan_dias_vigencia" placeholder="Ingrese aqui el número de dias que tiene vigente el plan">
                                            <div class="form-control-position">
                                                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldDiasVigencia.invalid && (fieldDiasVigencia.dirty || fieldDiasVigencia.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldDiasVigencia.errors.required">
                                                Campo Requerido
                                            </small>
                                        </p>
                                    </div>

                                    <div class="form-group form-check">
                                        <input type="checkbox" class="form-check-input" id="pla_mostrar_web" [(ngModel)]="formPlan.mostrar_web" name="pla_mostrar_web">
                                        <label class="form-check-label" for="pla_mostrar_web">Mostrar al Público?</label>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let modulo of esquemaFuncionalidades">
                    <div class="row">
                        <div class="col-md-12" style="margin: 0 auto;">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Modulo: {{modulo.nombre}}</h4>
                                    <a class="heading-elements-toggle"><i
                                            class="fa fa-ellipsis-v font-medium-3"></i></a>
                                    <div class="heading-elements">
                                        <ul class="list-inline mb-0">
                                            <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                            <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                            <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                            <li><a data-action="close"><i class="ft-x"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-content collapse show">
                                    <div class="card-body">

                                        <div *ngFor="let coleccion of modulo.colecciones">

                                            <div class="bs-callout-success callout-border-left mt-1 p-1 info-collection">
                                                <strong>{{coleccion.nombre}}</strong>
                                                <p>
                                                    Seleccione las funcionalidades de esta colección que va a incluir en el plan
                                                </p>
                                            </div>
                                            <br>
                                            <table class="table">
                                                <thead>
                                                    <th>
                                                        Funcionalidad
                                                    </th>
                                                    <th>
                                                        Estado
                                                    </th>
                                                    <th>
                                                        Limite
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let funcionalidad of coleccion.funcionalidades">
                                                        <td>
                                                            {{funcionalidad.nombre}}
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" (click)="toggleFunctionPlan(funcionalidad._id)" [checked]="checkExistFuncionPlan(funcionalidad._id)" class="form-control" name="estado_funcionalidad_{{funcionalidad._id}}" />
                                                        </td>
                                                        <td>
                                                            <div *ngIf="funcionalidad.maneja_limite && funcionalidad.tipo_limite == 'PLAN'">
                                                                <input type="number" [(ngModel)]="setLimitFunction(funcionalidad._id).registros_limite" [disabled]="!checkExistFuncionPlan(funcionalidad._id)" class="form-control" name="limite_funcionalidad_{{funcionalidad._id}}" placeholder="ingrese el limite de la funcion"
                                                                />
                                                                <p>
                                                                    permite indicar el número límite sobre esta función
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <br>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </section>
        </div>
    </form>
</div>