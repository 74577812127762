import { Direccion } from "./direccion";

export class Empresa {

    _id: string;
    tipo_identificacion: string;
    nit: string;
    dv: string;
    tipo_organizacion: "PN" | "PJ";
    email: string;
    razon_social: string;
    contacto: string;
    nombre_comercial: string;
    cuenta_id: string;
    plan_id: string;
    direccion: Direccion;
    alegra_id: string;
    created: number;
    udpated: number;

    constructor() {
        this._id = null;
        this.nit = null;
        this.tipo_identificacion = null;
        this.dv = null;
        this.email = null;
        this.razon_social = null;
        this.tipo_organizacion = null;
        this.contacto = null;
        this.nombre_comercial = null;
        this.cuenta_id = null;
        this.plan_id = null;
        this.direccion = new Direccion();
        this.alegra_id = null;
        this.created = null;
        this.udpated = null;
    }
}
