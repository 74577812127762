import { Component, OnInit } from '@angular/core';
import { CuentasService } from 'src/app/services/cuentas.service';
import { ActivatedRoute } from '@angular/router';
import { Cuenta } from 'src/app/entities/cuenta';

declare var jQuery;

@Component({
    selector: 'app-view-cuentas',
    templateUrl: './view-cuentas.component.html',
    styleUrls: ['./view-cuentas.component.scss']
})
export class ViewCuentasComponent implements OnInit {

    cuenta: Cuenta;

    constructor(
        private cuentasService: CuentasService,
        private currentRoute: ActivatedRoute,
    ) {

        this.cuenta = new Cuenta();

        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    ngOnInit() {
    }


    /**
     * Permite cargar la informacion de la cuenta a editar
     * @param _id 
     */
    private load(_id: string) {
        jQuery.fancybox.showLoading();
        this.cuentasService.get(_id)
            .subscribe((response: Cuenta) => {
                this.cuenta = response;
                jQuery.fancybox.hideLoading();
            })
    }


}
