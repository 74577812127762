export class Usuario {

    _id: string;
    username: string;
    permisos: any;
    password: string;

    constructor(){
        this._id = null;
        this.username = null;
        this.password = null;
        this.permisos = {
            "DASHBOARD_READ": false,
    
            "ADMIN_ACCOUNTS_CREATE": false,
            "ADMIN_ACCOUNTS_READ": false,
            "ADMIN_ACCOUNTS_UPDATE": false,
            "ADMIN_ACCOUNTS_DELETE": false,
    
            "ACCOUNTS_CREATE": false,
            "ACCOUNTS_READ": false,
            "ACCOUNTS_UPDATE": false,
            "ACCOUNTS_DELETE": false,
    
            "BUSINESS_CREATE": false,
            "BUSINESS_READ": false,
            "BUSINESS_UPDATE": false,
            "BUSINESS_DELETE": false,
    
            "PLANS_CREATE": false,
            "PLANS_READ": false,
            "PLANS_UPDATE": false,
            "PLANS_DELETE": false,
    
            "LICENSES_CREATE": false,
            "LICENSES_READ": false,
            "LICENSES_UPDATE": false,
            "LICENSES_DELETE": false,

            "MODULES_CREATE": false,
            "MODULES_READ": false,
            "MODULES_UPDATE": false,
            "MODULES_DELETE": false
        };
    }
}
