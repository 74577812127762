import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ImplementacionEmpresa } from '../entities/implementacion-empresa';

@Injectable({
  providedIn: 'root'
})
export class ImplementacionEmpresaService {

  constructor(public http:HttpClient) {}

  public getAll(){
    return this.http.get<Array<ImplementacionEmpresa>>(environment.apiUrl.admin + "/implementations");
  }

  public save(estado:ImplementacionEmpresa){
  	if (!estado._id) {
  		return this.add(estado);
  	}
  	return this.edit(estado);
  }

  private add(estado:ImplementacionEmpresa){
  	delete estado._id;
  	return this.http.post<ImplementacionEmpresa>(environment.apiUrl.admin+ "/implementations" , estado);
  }

  private edit(estado:ImplementacionEmpresa){
  	return this.http.put<ImplementacionEmpresa>(environment.apiUrl.admin+"/implementations/"+estado._id , estado);
  }

  public get(_id:String){
  	return this.http.get<ImplementacionEmpresa>(environment.apiUrl.admin + "/implementations/" + _id);
  }

  public getByBusiness(_id:String){
  	return this.http.get<ImplementacionEmpresa>(environment.apiUrl.admin + "/business/" + _id + "/implementations");
  }}
