import { Component, OnInit } from '@angular/core';
import { Licencia } from 'src/app/entities/licencia';
import { Empresa } from 'src/app/entities/empresa';
import { Plan } from 'src/app/entities/plan';
import { PlanesService } from 'src/app/services/planes.service';
import { LicenciasService } from 'src/app/services/licencias.service';
import { ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';
declare var jQuery;

@Component({
    selector: 'app-licencias-empresa',
    templateUrl: './licencias-empresa.component.html',
    styleUrls: ['./licencias-empresa.component.scss']
})
export class LicenciasEmpresaComponent implements OnInit {

    licencias: Array<Licencia>;
    planes: Array<Plan>;
    page: number;
    permisos: any;

    constructor(
        private planesService: PlanesService,
        private licenciasService: LicenciasService,
        private currentRoute: ActivatedRoute
    ) {
        this.planes = [];
        this.licencias = [];
        this.page = 1;
        this.permisos = JSON.parse(localStorage.userLogged).permisos;
    }

    ngOnInit() {
        // accedemos la informacion del router parent
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });

        this.loadPlanes();
    }

    /**
     * Permite cargar las licencias
     */
    private load(_id: string) {
        jQuery.fancybox.showLoading();
        this.licenciasService.getByEmpresa(_id)
            .subscribe((response: Array<Licencia>) => {
                this.licencias = response;
                jQuery.fancybox.hideLoading();
            })
    }


    /**
     * Permite cargar los planes
     */
    private loadPlanes() {
        this.planesService.getAll()
            .subscribe((response: Array<Plan>) => {
                this.planes = response;
            });
    }


    /**
     * Permite eliminar un plan registrado
     * @param _id 
     */
    public remove(_id: string, index: number) {
        Swal({
            type: 'warning',
            title: 'Desea eliminar la licencia seleccionada',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(succ => {
            if (succ.value) {
                jQuery.fancybox.showLoading();
                this.licenciasService.remove(_id)
                    .subscribe(() => {
                        this.licencias.splice(index, 1);
                        jQuery.fancybox.hideLoading();
                    });
            }
        });
    }

    /**
     * Permite obtener el nombre de una empresa por su id
     * @param _id 
     */
    public getLabelPlan(_id: string) {
        for (const plan of this.planes) {
            if (plan._id === _id) {
                return plan.nombre;
            }
        }
        return null;
    }


}
