export class FuncionalidadPlan {
    
    public _id: string;
    public plan_id: string;
    public funcionalidad_modulo_id: string;
    public registros_limite: number;
    public created: number;
    public updated: number;

    constructor(){
        this._id = null;
        this.plan_id = null;
        this.funcionalidad_modulo_id = null;
        this.registros_limite = -1;
        this.created = null;
        this.updated = null;
    }
}
