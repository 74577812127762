<div class="content-wrapper">

    <div class="content-header row">
        <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">
                Detalles de Licencia
            </h3>
            <div class="row breadcrumbs-top d-inline-block">
                <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/licencias">
                                licencias
                            </a>
                        </li>
                        <li class="breadcrumb-item active">
                            Lista de licencias
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-header-right col-md-4 col-12">
            <div class="btn-group float-md-right">
                <!-- 
                <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="icon-settings mr-1"></i>
                    Opciones
                </button>
                <div class="dropdown-menu arrow">
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-cart-plus mr-1"></i>
                        Cart
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-life-ring mr-1"></i>
                        Support
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-cog mr-1"></i>
                        Settings
                    </a>
                </div>
                 -->
            </div>
        </div>
    </div>


    <div class="content-body">

        <!-- Basic Tables start -->
        <div class="row">

            <div class="col-12">

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">
                            Detalles de Licencia
                        </h4>
                        <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                <li><a data-action="close"><i class="ft-x"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="card-content collapse show">

                        <div class="card-body">
                            <p class="card-text">
                                Aqui se muestra los detalles de la licencia
                            </p>
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>
                                                Codigo
                                            </th>
                                            <td>
                                                {{licencia._id}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                consecutivo
                                            </th>
                                            <td>
                                                {{licencia.consecutivo}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Empresa
                                            </th>
                                            <td>
                                                {{empresa.razon_social}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Plan
                                            </th>
                                            <td>
                                                {{plan.nombre}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Fecha inicio
                                            </th>
                                            <td>
                                                {{licencia.fecha_inicio | date}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Fecha Vencimiento
                                            </th>
                                            <td>
                                                {{licencia.fecha_vencimiento | date}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Estado
                                            </th>
                                            <td>
                                                {{licencia.estado}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <br>
                            <br>

                            <h4>
                                Transacciones
                            </h4>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                ref
                                            </th>
                                            <th>
                                                valor total
                                            </th>
                                            <th>
                                                estado
                                            </th>
                                            <th>
                                                fecha
                                            </th>
                                            <th>
                                                respuesta
                                            </th>
                                            <th>
                                                descripcion
                                            </th>
                                            <th>
                                                medio de pago
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let transaccion of transacciones">
                                            <td>
                                                {{transaccion._id}}
                                            </td>
                                            <td>
                                                {{transaccion.ref_epayco}}
                                            </td>
                                            <td>
                                                {{transaccion.valor_total}}
                                            </td>
                                            <td>
                                                {{transaccion.estado}}
                                            </td>
                                            <td>
                                                {{transaccion.fecha}}
                                            </td>
                                            <td>
                                                {{transaccion.respuesta}}
                                            </td>
                                            <td>
                                                {{transaccion.descripcion}}
                                            </td>
                                            <td>
                                                {{transaccion.medio_pago}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- Basic Tables end -->

    </div>


</div>