import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConexionBd } from 'src/app/entities/conexion-bd';
import { ConexionesBdService } from 'src/app/services/conexiones-bd.service';
import Swal from 'sweetalert2';
declare var jQuery;

@Component({
    selector: 'app-form-conexiones-bd',
    templateUrl: './form-conexiones-bd.component.html',
    styleUrls: ['./form-conexiones-bd.component.scss']
})
export class FormConexionesBdComponent implements OnInit {

    formData: ConexionBd;

    constructor(
        private currentRoute: ActivatedRoute,
        private conexionesBdService: ConexionesBdService
    ) {
        this.formData = new ConexionBd();
        this.formData.port = "27017";

        // accedemos la informacion del router parent
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.formData.empresa_id = params.id;
                this.load(params.id);
            }
        });
    }

    ngOnInit() {
    }

    /**
     * Permite cargar la informacion de la conexion
     * @param _id 
     */
    private load(_id: string) {
        jQuery.fancybox.showLoading();
        this.conexionesBdService.getByEmpresa(_id)
            .subscribe((response: ConexionBd) => {
                this.formData = response;
                jQuery.fancybox.hideLoading();
            }, () => {
                jQuery.fancybox.hideLoading();
            });

    }

    /**
     * Permite guardar los cambios de la conexion
     */
    public save() {
        

        // verificamos si se esta estableciendo una conexion
        if (this.formData._id === null) {
            delete this.formData._id;
            Swal({
                type: 'info',
                title: 'Desea inicializar base de datos',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then(succ => {
                jQuery.fancybox.showLoading();
                this.conexionesBdService.add(this.formData, succ.value)
                    .subscribe((response: ConexionBd) => {

                        // establecemos el id, por si seguidamente se edita el formulario, 
                        // para que no se vaya a crear un nuevo registros
                        this.formData._id = response._id;

                        Swal({
                            type: 'success',
                            title: 'Hecho',
                            text: "conexión creada correctamente"
                        });
                        jQuery.fancybox.hideLoading();
                    });
            });

        } else {
            jQuery.fancybox.showLoading();
            this.conexionesBdService.edit(this.formData)
                .subscribe((response) => {
                    Swal({
                        type: 'success',
                        title: 'Hecho',
                        text: response['message']
                    });
                    jQuery.fancybox.hideLoading();
                });
        }


    }


}
