import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	public uris = [
		"https://app.databox.com/datawall/cb528d5bb93a412d1cdcd231756b89dd05da7428e?i",
		"https://app.databox.com/datawall/cb35299bd532126eeabfd36c8cd06cdc05daa1ab7?i",
		"https://app.databox.com/datawall/6e598f2c6135f3ee34e0a7a29c2bec9905f773c08?i",
		"https://app.databox.com/datawall/1b1f070b9ab1fac00fd5644995f08c010601aa262?i",
		"https://app.databox.com/datawall/41a8a943a1395218991222e89d7ac7650601aa711?i",
		"https://app.databox.com/datawall/4fd064c339ce98e23c23f71050e6c32006033c320?i"
	];

	public index = 0;
	private databox = null;

	constructor(public http:HttpClient) {
	}
	ngOnInit() {
		this.http.get(environment.apiUrl.admin + "/accounts").subscribe(succ=>console.log(succ),err=>console.log(err));
		this.switchDatabox();
	}

	switchDatabox(){
		this.databox = document.getElementById('iframe-databox') as unknown as HTMLIFrameElement;

		if(this.index >= this.uris.length){
			this.index = 0;
		}
		
		if(this.databox !== null)
			this.databox.src = this.uris[this.index++];
		else {
			setTimeout(()=>this.switchDatabox, 100 * 60);
			return;
		}

		setTimeout(()=>this.switchDatabox(), 1000 * 60);
	}

}
