import { Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
var HttpInterceptorService = /** @class */ (function () {
    function HttpInterceptorService(injector) {
        this.injector = injector;
    }
    /**
     * Permite verificar si se esta haciendo una peticion a la api del proyecto
     * @param urlRequest
     */
    HttpInterceptorService.prototype.isRequestToApi = function (urlRequest) {
        var apiUrl = new URL(environment.apiUrl.root);
        var urlReq = new URL(urlRequest);
        // verificamos si el host es el mismo del host de la api
        if (apiUrl.hostname === urlReq.hostname) {
            // verificamos si se esta haciendo uso de la api como tal
            if (urlRequest.includes(apiUrl.href)) {
                return true;
            }
        }
        return false;
    };
    /**
     * Permite organizar el request que va dirigido a la api para adicionarle el access token
     * @param req
     */
    HttpInterceptorService.prototype.cloneRequestToApi = function (req) {
        var authService = this.injector.get(AuthService);
        // en caso de que no haya token devolemos el mismo request
        if (authService.token === null) {
            return req;
        }
        // clonamos el request y le adicionamos el access token
        var clonReq = req.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                'Access-Token': authService.token
            }
        });
        return clonReq;
    };
    /**
     * Metodo principal que se ejecuta por cada llamado de un http Client
     * @param req
     * @param next
     */
    HttpInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        // console.log(req)
        // en caso de que no sea una peticion al servidor api dejamos la solicitud tal cual
        if (!this.isRequestToApi(req.url)) {
            return next.handle(req);
        }
        // como la peticion es para el servidor api, se hace un clon de la solicitud
        // y se le adiciona el access token para las credenciales
        return next.handle(this.cloneRequestToApi(req))
            .pipe(tap(function (event) {
            // logging the http response to browser's console in case of a success
            if (event instanceof HttpResponse) {
                // console.log('api call success : ', event);
            }
        }, function (error) {
            // logging the http response to browser's console in case of a failuer
            if (event instanceof HttpResponse) {
                // console.log('api call error : ', event);
            }
            else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                console.error("Backend returned code " + error.status + ", " +
                    ("body was: " + error.error.message));
                if (error.status === 498 || error.status === 499 || error.status === 401) {
                    var authService = _this.injector.get(AuthService);
                    var router = _this.injector.get(Router);
                    authService.closeSession();
                    jQuery.fancybox.hideLoading();
                    router.navigate(['/login']);
                }
            }
        }));
    };
    HttpInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(i0.ɵɵinject(i0.INJECTOR)); }, token: HttpInterceptorService, providedIn: "root" });
    return HttpInterceptorService;
}());
export { HttpInterceptorService };
