import { Component, OnInit } from '@angular/core';
import { PlanesService } from 'src/app/services/planes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Plan } from 'src/app/entities/plan';

declare var jQuery;

@Component({
    selector: 'app-view-planes',
    templateUrl: './view-planes.component.html',
    styleUrls: ['./view-planes.component.scss']
})
export class ViewPlanesComponent implements OnInit {

    plan: Plan;

    constructor(
        private planesService: PlanesService,
        private router: Router,
        private currentRoute: ActivatedRoute
    ) {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    ngOnInit() {
    }

    /**
     * Permite cargar la informacion del plan, para que se muestre en el formulario y se pueda editar
     * @param _id 
     */
    private load(_id: string) {
        jQuery.fancybox.showLoading();
        this.planesService.get(_id)
            .subscribe((response: Plan) => {
                this.plan = response;
                jQuery.fancybox.hideLoading();
            })
    }

}
