import { Component, OnInit } from '@angular/core';
import { Plan } from 'src/app/entities/plan';
import { PlanesService } from 'src/app/services/planes.service';
import Swal from 'sweetalert2';
declare var jQuery;
@Component({
    selector: 'app-planes',
    templateUrl: './planes.component.html',
    styleUrls: ['./planes.component.scss']
})
export class PlanesComponent implements OnInit {

    planes: Array<Plan>;
    search: string;
    permisos: any;
    constructor(private planesService: PlanesService) {
        this.planes = [];
        this.search = null;
        this.permisos = JSON.parse(localStorage.userLogged).permisos;
        this.load();
    }

    ngOnInit() {
    }

    /**
     * Permite cargar los planes 
     */
    private load() {
        jQuery.fancybox.showLoading();
        this.planesService.getAll()
            .subscribe((response: Array<Plan>) => {
                this.planes = response;
                jQuery.fancybox.hideLoading();
            });
    }


    /**
     * Permite eliminar un plan registrado
     * @param _id 
     */
    public remove(_id: string, index: number) {
        Swal({
            type: 'warning',
            title: 'Desea eliminar el plan seleccionado',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(succ => {
            if (succ.value) {
                jQuery.fancybox.showLoading();
                this.planesService.remove(_id)
                    .subscribe(() => {
                        this.planes.splice(index, 1);
                        jQuery.fancybox.hideLoading();
                    });
            } 
        });
        
    }
}