import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImplementacionEmpresa } from '../../entities/implementacion-empresa';
import { ImplementacionEmpresaService } from '../../services/implementacion-empresa.service';
declare var $;

@Component({
  selector: 'app-implementaciones-empresas',
  templateUrl: './implementaciones-empresas.component.html',
  styleUrls: ['./implementaciones-empresas.component.scss']
})
export class ImplementacionesEmpresasComponent implements OnInit {
	public estado:ImplementacionEmpresa;
  	public porcentaje;
	public selectos:number;
	constructor(public states:ImplementacionEmpresaService, public router:ActivatedRoute) {
		this.estado = new ImplementacionEmpresa();
		this.selectos = 0;
		this.getPorcentaje();
	}
		
	ngOnInit() {
		this.load();
	}

	public load(){
		this.router.parent.params.subscribe(estado=>{
			if(typeof estado.id!=="undefined"){
				this.states.getByBusiness(estado.id).subscribe(impelmentacion=>{
					if(impelmentacion == null){
						this.estado=new ImplementacionEmpresa();
						this.estado.empresa_id = estado.id;
					}else{
						this.estado = impelmentacion;
					}

				});
			}
		});
	}
	public enviar(){
		this.states.save(this.estado).subscribe(estado=>this.porcentaje= this.getPorcentaje());
	}
	public getPorcentaje(){
		let totales = 0;

		this.selectos = 0;
		
		for(let esta in this.estado){
			for(let este in this.estado[esta]){
				if(esta!="empresa_id" && esta!="_id"){
					if(this.estado[esta][este]){
						this.selectos +=1;
					}
					totales++;
				}
			}
		}

		//Detalles
		return Math.floor((this.selectos/totales)*100);
	}

}
