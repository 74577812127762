import { Component, OnInit } from '@angular/core';
import { Cuenta } from 'src/app/entities/cuenta';
import { Licencia } from 'src/app/entities/licencia';
import { Empresa } from 'src/app/entities/empresa';
import { Plan } from 'src/app/entities/plan';
import { Transaccion } from 'src/app/entities/transaccion';
import { EmpresasService } from 'src/app/services/empresas.service';
import { PlanesService } from 'src/app/services/planes.service';
import { LicenciasService } from 'src/app/services/licencias.service';
import { CuentasService } from 'src/app/services/cuentas.service';
import { ActivatedRoute } from '@angular/router';
import { EpaycoTransaccionesService } from 'src/app/services/epayco-transacciones.service';

@Component({
  selector: 'app-view-licencias',
  templateUrl: './view-licencias.component.html',
  styleUrls: ['./view-licencias.component.scss']
})
export class ViewLicenciasComponent implements OnInit {

    cuenta: Cuenta;
    licencia: Licencia;
    empresa: Empresa;
    plan: Plan;
    transacciones: Array<Transaccion>;

    constructor(
        private empresaService: EmpresasService,
        private planService: PlanesService,
        private licenciasService: LicenciasService,
        private cuentasService: CuentasService,
        private epaycoService: EpaycoTransaccionesService,
        private currentRoute: ActivatedRoute
    ) {
        this.empresa = new Empresa();
        this.plan = new Plan();
        this.cuenta = new Cuenta();
        this.licencia = new Licencia();
        this.transacciones = [];
    }

    ngOnInit() {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
                this.loadTransacciones(params.id);
            }
        });
    }

    /**
     * Permite cargar la informacion de la licencia para ser editado
     * @param _id 
     */
    private load(_id: string) {
        this.licenciasService.get(_id)
            .subscribe((response: Licencia) => {
                this.licencia = response;

                this.loadCuenta(this.licencia.cuenta_id);
                this.loadEmpresa(this.licencia.empresa_id);
                this.loadPlan(this.licencia.plan_id);
            });
    }

    /**
     * Permite cargar las empresas
     */
    private loadEmpresa(id: string) {
        this.empresaService.get(id)
            .subscribe((response: Empresa) => {
                this.empresa = response;
            });
    }

    /**
     * Permite cargar los planes
     */
    private loadPlan(id: string) {
        this.planService.get(id)
            .subscribe((response: Plan) => {
                this.plan = response;
            });
    }

    /**
     * Permite cargar todas las cuentas
     */
    private loadCuenta(id: string) {
        this.cuentasService.get(id)
            .subscribe((response: Cuenta) => {
                this.cuenta = response;
            });
    }

    /**
     * Permite cargar las transacciones hechas para una licencia
     * @param id 
     */
    private loadTransacciones(id: string) {
        this.epaycoService.getByLicencia(id)
            .subscribe((response: Array<Transaccion>) => {
                this.transacciones = response;
            });
    }


}
