import { Component, OnInit } from '@angular/core';
import { Cuenta } from 'src/app/entities/cuenta';
import { CuentasService } from 'src/app/services/cuentas.service';
import swal from 'sweetalert2';

declare var jQuery;

@Component({
    selector: 'app-cuentas',
    templateUrl: './cuentas.component.html',
    styleUrls: ['./cuentas.component.scss']
})
export class CuentasComponent implements OnInit {

    today: Date = new Date();
    cuentas: Array<Cuenta>;
    cuentasFiltradas: Array<Cuenta>;
    search: string;
    page: number;
    permisos: any;

    filtro: {fecha_inicio: string, fecha_fin: string} = {
        fecha_inicio: `${this.today.getFullYear()}-${((this.today.getMonth()+1)+'').length > 1 ? (this.today.getMonth()+1) : '0'+(this.today.getMonth()+1)}-${(this.today.getDate()+'').length > 1 ? this.today.getDate() : '0'+this.today.getDate()}`,
        fecha_fin: `${this.today.getFullYear()}-${((this.today.getMonth()+1)+'').length > 1 ? (this.today.getMonth()+1) : '0'+(this.today.getMonth()+1)}-${(this.today.getDate()+'').length > 1 ? this.today.getDate() : '0'+this.today.getDate()}`
    }

    constructor(
        private cuentasService: CuentasService
    ) {
        this.cuentas = [];
        this.search = null;
        this.page = 1;
        this.permisos = JSON.parse(localStorage.userLogged).permisos;
    }

    ngOnInit() {
        this.load();
    }

    private load() {
        // jQuery.fancybox.showLoading();
        this.cuentasService.getAll()
            .subscribe((response: Array<Cuenta>) => {
                this.cuentas = response;
                this.cuentasFiltradas = response;
                // jQuery.fancybox.hideLoading();
                console.log(this.cuentas);
            })
    }

    filter(){
        const unixStart = (new Date(this.filtro.fecha_inicio)).getTime();
        const unixEnd = (new Date(this.filtro.fecha_fin)).getTime();

        this.cuentasFiltradas = [];

        for (const i in this.cuentas){
            if (this.cuentas[i].created['$date']['$numberLong'] > unixStart && this.cuentas[i].created['$date']['$numberLong'] < unixEnd){
                this.cuentasFiltradas.push(this.cuentas[i]);
            }
        }
    }

    /**
     * Permite eliminar un registro
     * @param _id identificador del registro
     * @param i key o index del registro
     */
    public remove(_id: string, i: number) {
        swal({
            title: 'Está seguro que desea eliminar esta cuenta?',
            showConfirmButton: true,
            showCancelButton: true,
            type: 'question'
        }).then(succ => {
            if (succ.value) {
                this.cuentasService.remove(_id)
                    .subscribe(succ => {
                        this.cuentas.splice(i,1);
                        swal({
                            title: 'Se eliminó correctamente',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    });
            }
        });
    }

}
