import { Injectable } from '@angular/core';
import { Modulo } from '../entities/modulo';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class ModulosService {

    constructor(private http: HttpClient) { }

    /**
     * Permite obtener todos los registros de modulos que hay en la base de datos
     */
    public getAll() {
        return this.http.get(environment.apiUrl.admin + "/modules");
    }

    /**
     * Permite obtener un registro relacionado con el id
     * @param id 
     */
    public getById(id: String) {
        return this.http.get(environment.apiUrl.admin + "/modules/" + id);
    }

    /**
     * Permite guardar la entidad de un modulo
     * @param formData 
     */
    public save(formData: Modulo) {
        return (formData._id === null) ? this.add(formData) : this.edit(formData);
    }

    /**
     * Permite agregar un modulo
     * @param formData 
     */
    private add(formData: Modulo) {
        delete formData._id;
        return this.http.post(environment.apiUrl.admin + "/modules", formData);
    }
    
    /**
     * Permiter guardar los cambios de un modulo
     * @param formData 
     */
    private edit(formData: Modulo) {
        return this.http.put(environment.apiUrl.admin + "/modules/" + formData._id, formData);
    }

    /**
     * Permite eliminar un modulo
     * @param _id 
     */
    public delete(_id: String) {
        return this.http.delete(environment.apiUrl.admin + "/modules/" + _id);
    }
}
