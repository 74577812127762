import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FuncionalidadPlan } from '../entities/funcionalidad-plan';

@Injectable({
    providedIn: 'root'
})
export class FuncionalidadesPlanService {

    constructor(private http: HttpClient) { }

    /**
     * Permite obtener un registro relacionado con el id
     * @param id 
     */
    public getByPlanId(id: String) {
        return this.http.get(environment.apiUrl.admin + "/plans/" + id + "/functionalities");
    }

    /**
     * Permite agregar un modulo
     * @param formData 
     */
    public setByPlanId(id: string, formData: Array<FuncionalidadPlan>) {
        return this.http.post(environment.apiUrl.admin + "/plans/" + id + "/functionalities", formData);
    }

}
