import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import {NgxPaginationModule} from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavComponent } from './components/nav/nav.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RequireAuthDirective } from './directives/require-auth.directive';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { PlanesComponent } from './pages/planes/planes.component';
import { Select2Directive } from './directives/select2.directive';
import { FormPlanesComponent } from './pages/form-planes/form-planes.component';
import { ViewPlanesComponent } from './pages/view-planes/view-planes.component';
import { LicenciasComponent } from './pages/licencias/licencias.component';
import { FormLicenciasComponent } from './pages/form-licencias/form-licencias.component';
import { DatePipe } from './pipes/date.pipe';
import { DatetimePipe } from './pipes/datetime.pipe';
import { FormCuentasComponent } from './pages/form-cuentas/form-cuentas.component';
import { CuentasComponent } from './pages/cuentas/cuentas.component';
import { CuentasAdministradoresComponent } from './pages/cuentas-administradores/cuentas-administradores.component';
import { FormCuentasAdminComponent } from './pages/form-cuentas-admin/form-cuentas-admin.component';
import { LicenciasCuentaComponent } from './pages/licencias-cuenta/licencias-cuenta.component';
import { LicenciasEmpresaComponent } from './pages/licencias-empresa/licencias-empresa.component';
import { EmpresasComponent } from './pages/empresas/empresas.component';
import { EmpresasCuentaComponent } from './pages/empresas-cuenta/empresas-cuenta.component';
import { ViewCuentasComponent } from './pages/view-cuentas/view-cuentas.component';
import { FormPasswordCuentaComponent } from './pages/form-password-cuenta/form-password-cuenta.component';
import { FormEmpresasComponent } from './pages/form-empresas/form-empresas.component';
import { ViewEmpresasComponent } from './pages/view-empresas/view-empresas.component';
import { FormConexionesBdComponent } from './pages/form-conexiones-bd/form-conexiones-bd.component';
import { ImplementacionesEmpresasComponent } from './pages/implementaciones-empresas/implementaciones-empresas.component';
import { FormModulosComponent } from './pages/form-modulos/form-modulos.component';
import { ModulosComponent } from './pages/modulos/modulos.component';
import { ViewModulosComponent } from './pages/view-modulos/view-modulos.component';
import { FormFuncionalidadModuloComponent } from './pages/form-funcionalidad-modulo/form-funcionalidad-modulo.component';
import { GridFilterPipe } from './pipes/grid-filter.pipe';
import { DateFilterPipe } from './pipes/date-filter.pipe';
import { ViewLicenciasComponent } from './pages/view-licencias/view-licencias.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    NavComponent,
    LoginComponent,
    PageNotFoundComponent,
    DashboardComponent,
    RequireAuthDirective,
    PlanesComponent,
    Select2Directive,
    FormPlanesComponent,
    ViewPlanesComponent,
    LicenciasComponent,
    FormLicenciasComponent,
    DatePipe,
    DatetimePipe,
    FormCuentasComponent,
    CuentasComponent,
    CuentasAdministradoresComponent,
    FormCuentasAdminComponent,
    LicenciasCuentaComponent,
    LicenciasEmpresaComponent,
    EmpresasComponent,
    EmpresasCuentaComponent,
    ViewCuentasComponent,
    FormPasswordCuentaComponent,
    FormEmpresasComponent,
    ViewEmpresasComponent,
    ImplementacionesEmpresasComponent,
    FormConexionesBdComponent,
    FormModulosComponent,
    ModulosComponent,
    ModulosComponent,
    ViewModulosComponent,
    FormFuncionalidadModuloComponent,
    GridFilterPipe,
    DateFilterPipe,
    ViewLicenciasComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgxPaginationModule
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptorService,
        multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
