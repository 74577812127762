import { Component, OnInit } from '@angular/core';
import { Modulo } from '../../entities/modulo';
import {ModulosService} from  '../../services/modulos.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.scss']
})
export class ModulosComponent implements OnInit {
	public modulos:Array<Modulo>;
	public permisos: any;

	constructor(private modulosService:ModulosService) { 
		this.modulos = [];
		this.permisos = JSON.parse(localStorage.userLogged).permisos;
	}

	ngOnInit() {
		this.load();
	}
	public load(){
		this.modulosService.getAll().subscribe((resp:Array<Modulo>)=>{
			this.modulos = resp
		});
	}

	public remove(_id:String,i:number){
		swal({
			type:'question',
			showCancelButton:true,
			showConfirmButton:true,
			title:'Está seguro de lo que va a hacer?',
			text:'Si confirma este registro no volverá'
		}).then(Response=>{
			if(Response.value){
				this.modulosService.delete(_id).subscribe(succ=>{
					swal({
						type:'success',
						showConfirmButton:false,
						timer:1000,
						title:'Se eliminó',
						text:'este registro se eliminó correctamente'
					});
					this.modulos.splice(i,1);
				})	
			}
		})
	}
}
