<div class="content-wrapper">
    <div class="content-header row">
        <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">
                Formulario Funcionalidades
            </h3>
            <div class="row breadcrumbs-top d-inline-block">
                <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/modulos">
                                Modulos
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/modulos/{{formData.modulo_id}}/detalles">
                                Funcionalidades
                            </a>
                        </li>
                        <li class="breadcrumb-item active">Formulario funcionalidades</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-header-right col-md-4 col-12">
            <div class="btn-group float-md-right">
                <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="icon-settings mr-1"></i>
                    Opciones
                </button>
                <div class="dropdown-menu arrow">
                    <a class="dropdown-item" routerLink="/planes/add">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        Nuevo Modulo
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-cart-plus mr-1"></i>
                        Cart
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-life-ring mr-1"></i>
                        Support
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-cog mr-1"></i>
                        Settings
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="content-body">
        <!-- Basic form layout section start -->
        <section>
            <div class="row ">
                <div class="col-md-6 col-md-offset-3" style="margin: 0 auto;">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Información del modulo</h4>
                            <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                    <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                    <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                    <li><a data-action="close"><i class="ft-x"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content collapse show">
                            <div class="card-body">

                                <div class="card-text">
                                    <p>
                                        Diligencie el siguiente formulario con la información del modulo
                                    </p>
                                </div>

                                <form class="form" (ngSubmit)="save()" #formFuncionalidades="ngForm">
                                    <div class="form-body">

                                        <div class="form-group">
                                            <label for="modulo">
                                                Nombre
                                            </label>
                                            <input type="text" name="nombreFuncionalidad" class="form-control" required
                                                #fieldNombreFuncionalidad="ngModel" id="modulo"
                                                [(ngModel)]="formData.nombre">
                                            <p class="block-tag text-right"
                                                *ngIf="fieldNombreFuncionalidad.invalid && (fieldNombreFuncionalidad.dirty || fieldNombreFuncionalidad.touched)">
                                                <small class="badge badge-default badge-danger"
                                                    *ngIf="fieldNombreFuncionalidad.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>
                                        <div class="form-group">
                                            <label for="hook">
                                                Hook
                                            </label>
                                            <input type="text" name="hook" class="form-control" required
                                                #fieldHook="ngModel" id="hook" [(ngModel)]="formData.hook">
                                            <p class="block-tag text-right"
                                                *ngIf="fieldHook.invalid && (fieldHook.dirty || fieldHook.touched)">
                                                <small class="badge badge-default badge-danger"
                                                    *ngIf="fieldHook.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>

                                        <div class="form-group">
                                            <label for="coleccion">
                                                Coleccion:
                                            </label>
                                            <input type="text" name="collection" class="form-control" required
                                                #fieldCollection="ngModel" id="coleccion"
                                                [(ngModel)]="formData.coleccion">
                                            <p class="block-tag text-right"
                                                *ngIf="fieldCollection.invalid && (fieldCollection.dirty || fieldCollection.touched)">
                                                <small class="badge badge-default badge-danger"
                                                    *ngIf="fieldCollection.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>
                                        <div class="form-group">
                                            <label for="checkDoc" class="control-label mr-1">
                                                Maneja Documento
                                            </label>
                                            <input type="checkbox" name="manejaDocu" #fieldManejaDocu="ngModel"
                                                id="checkDoc" [(ngModel)]="formData.maneja_documento">
                                        </div>

                                        <div class="form-group">
                                            <label for="checkLimit" class="control-label mr-1">
                                                Maneja Limites
                                            </label>
                                            <input type="checkbox" name="manejaLimite" #fieldManejaLimite="ngModel" id="checkLimit" [(ngModel)]="formData.maneja_limite">
                                        </div>

                                        <div class="form-group" *ngIf="formData.maneja_limite">
                                            <label for="tipo_limite">
                                                Tipo de Limite
                                            </label>
                                            <select name="tipo_limite" id="tipo_limite" required #fieldTipoLimite="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.tipo_limite">
                                                <option value="PLAN">
                                                    Para Empresas
                                                </option>
                                                <option value="ROL">
                                                    Para Usuarios
                                                </option>
                                            </select>
                                            <p class="block-tag text-right" *ngIf="fieldTipoLimite.invalid && (fieldTipoLimite.dirty || fieldTipoLimite.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="fieldTipoLimite.errors.required">
                                                    Debe seleccionar una opción
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="form-actions right">
                                        <a class="btn btn-light mr-1" routerLink="/modulos">
                                            <i class="ft-x"></i> Cancelar
                                        </a>
                                        <button [disabled]="!formFuncionalidades.valid" type="submit"
                                            class="btn btn-success">
                                            <i class="fa fa-check-square-o"></i> Guardar
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- // Basic form layout section end -->
    </div>
</div>