import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Cuenta } from 'src/app/entities/cuenta';
import { CuentasService } from 'src/app/services/cuentas.service';
import { ImplementacionEmpresa } from '../../entities/implementacion-empresa';
import { ImplementacionEmpresaService } from '../../services/implementacion-empresa.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

declare var jQuery;

@Component({
    selector: 'app-empresas',
    templateUrl: './empresas.component.html',
    styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {

    empresas: any[];
    cuentas: Array<Cuenta>;
    search: string;
    implementaciones: Array<ImplementacionEmpresa>;
    page: number;
    isChecked: boolean = false;

    constructor(
        private empresasService: EmpresasService,
        private cuentasService: CuentasService,
        public implementacionEmpresaService: ImplementacionEmpresaService,
        public http: HttpClient
    ) {
        this.implementaciones = [];
        this.empresas = [];
        this.cuentas = [];
        this.search = null;
        this.page = 1;
    }

    ngOnInit() {
        this.load();
        this.loadCuentas();
        this.loadImplentaciones();
    }

    /**
     * permite cargar las empresas
     */
    private load() {
        jQuery.fancybox.showLoading();
        this.empresasService
            .getAll()
            .subscribe((response: any) => {
                this.empresas = response;
                jQuery.fancybox.hideLoading();
            });
    }

    /**
     * Permite cargar las cuentas
     */
    private loadCuentas() {
        this.cuentasService.getAll()
            .subscribe((response: Array<Cuenta>) => {
                this.cuentas = response;
            });
    }

    private loadImplentaciones() {
        this.implementacionEmpresaService
            .getAll()
            .subscribe((implementaciones: Array<ImplementacionEmpresa>) => {
                this.implementaciones = implementaciones;
            });
    }

    /**
     * Permite obtener el nombre de la cuenta
     * @param cuenta_id 
     */
    public getLabelCuenta(cuenta_id: string): string {
        for (const cuenta of this.cuentas) {
            if (cuenta._id === cuenta_id) {
                return cuenta.nombres + ' ' + cuenta.apellidos;
            }
        }
        return null;
    }

    public getPorcentaje(id) {
        let estado: ImplementacionEmpresa;

        this.implementaciones.forEach(implementacion => {
            if (implementacion.empresa_id == id) {
                estado = implementacion;
            } else {
                estado = new ImplementacionEmpresa();
                estado.empresa_id = id;
            }
        });

        let selectos = 0;
        for (let esta in estado) {
            for (let este in estado[esta]) {
                if (esta != "empresa_id" && esta != "_id") {
                    if (estado[esta][este]) {
                        selectos += 1;
                    }
                }
            }
        }
        return Math.round(selectos * 100 / 43);
    }



    /**
     * permite vincular la compañia con alegra
     * 
     * @param company 
     */
    public linkCompanyToAlegra(idcompany) {
        jQuery.fancybox.showLoading();
        this.http
            .post(environment.apiUrl.admin + '/bussines/' + idcompany + '/add-to-alegra', {})
            .subscribe((response: any) => {
                
                Swal({
                    type: 'success',
                    title: 'Hecho',
                    text: response['message']
                });

                // volvemos a cargar la informacion de las compañias
                this.load();

            }, (responseError) => {
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: responseError.error['message']
                });
                jQuery.fancybox.hideLoading();
            });
    }


    /**
     * permite actualizar la informacion de la compañia en alegra
     * 
     * @param idcompany 
     */
    public updateCompanyToAlegra(idcompany) {
        jQuery.fancybox.showLoading();
        this.http
            .post(environment.apiUrl.admin + '/bussines/' + idcompany + '/update-to-alegra', {})
            .subscribe((response: any) => {
                Swal({
                    type: 'success',
                    title: 'Hecho',
                    text: response['message']
                });
                jQuery.fancybox.hideLoading();
            }, (responseError) => {
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: responseError.error['message']
                });
                jQuery.fancybox.hideLoading();
            });
    }

}


