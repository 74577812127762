import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FuncionalidadesModuloService = /** @class */ (function () {
    function FuncionalidadesModuloService(http) {
        this.http = http;
    }
    /**
     * Permite obtener todas las funcionalidades agrupadads por coleccione y las colecciones agrupadas por modulo
     */
    FuncionalidadesModuloService.prototype.getAllByScheme = function () {
        return this.http.get(environment.apiUrl.admin + "/functionalities-scheme");
    };
    FuncionalidadesModuloService.prototype.getById = function (id) {
        return this.http.get(environment.apiUrl.admin + "/modules-functionalities/" + id);
    };
    FuncionalidadesModuloService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl.admin + "/modules-functionalities");
    };
    FuncionalidadesModuloService.prototype.getByModule = function (module_id) {
        return this.http.get(environment.apiUrl.admin + "/modules/" + module_id + "/modules-functionalities ");
    };
    FuncionalidadesModuloService.prototype.save = function (form_data) {
        console.log(form_data._id === null);
        if (form_data._id === null) {
            return this.add(form_data);
        }
        return this.edit(form_data);
    };
    FuncionalidadesModuloService.prototype.add = function (form_data) {
        delete form_data._id;
        return this.http.post(environment.apiUrl.admin + "/modules-functionalities", form_data);
    };
    FuncionalidadesModuloService.prototype.edit = function (form_data) {
        return this.http.put(environment.apiUrl.admin + "/modules-functionalities/" + form_data._id, form_data);
    };
    FuncionalidadesModuloService.prototype.delete = function (_id) {
        return this.http.delete(environment.apiUrl.admin + "/modules-functionalities/" + _id);
    };
    FuncionalidadesModuloService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuncionalidadesModuloService_Factory() { return new FuncionalidadesModuloService(i0.ɵɵinject(i1.HttpClient)); }, token: FuncionalidadesModuloService, providedIn: "root" });
    return FuncionalidadesModuloService;
}());
export { FuncionalidadesModuloService };
