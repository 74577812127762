export class UpdatePassword {

    public _id: string;
    public new_pass: string;
    public renew_pass: string;

    constructor() {
        this._id = null;
        this.new_pass = null;
        this.renew_pass = null;
    }
}
