
export class Plan {
    public _id: string;
    public nombre: string;
    public precio: number;
    public dias_prueba: number;
    public dias_vigencia: number;
    public mostrar_web: boolean;
    public created: number;
    public updated: number;

    constructor(){
        this._id = null;
        this.nombre = null;
        this.precio = null;
        this.dias_prueba = null;
        this.dias_vigencia = null;
        this.mostrar_web = false;
        this.created = null;
        this.updated = null;
    }
}

