<div class="content-wrapper">

        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">
                    Licencias
                </h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a routerLink="/dashboard">
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item">
                                <a routerLink="/licencias">
                                    Licencias
                                </a>
                            </li>
                            <li class="breadcrumb-item active">
                                Formulario Licencias
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-4 col-12">
                <div class="btn-group float-md-right">
                    <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="icon-settings mr-1"></i>
                        Opciones
                    </button>
                    <div class="dropdown-menu arrow">
                        <a class="dropdown-item" routerLink="/planes/add">
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            Nuevo Plan
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="fa fa-cart-plus mr-1"></i>
                            Cart
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="fa fa-life-ring mr-1"></i>
                            Support
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">
                            <i class="fa fa-cog mr-1"></i>
                            Settings
                        </a>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section>
                <div class="row " >
                    <div class="col-md-6 col-md-offset-3" style="margin: 0 auto;">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Información de la Licencia</h4>
                                <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                                <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                        <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                        <li><a data-action="close"><i class="ft-x"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body">
    
                                    <div class="card-text">
                                        <p>
                                            Diligencie el siguiente formulario con la información de la licencia
                                        </p>
                                    </div>
    
                                    <form class="form" (ngSubmit)="save()" #formLicencias="ngForm">
                                        <div class="form-body">
    
                                            <div class="form-group" *ngIf="formData._id == null || (formData._id != null && formData.cuenta_id != null)">
                                                <label for="licencia_cuenta">
                                                    Cuenta
                                                </label>
                                                <select name="licencia_cuenta" id="licencia_cuenta" class="form-control" [appSelect2] [(ngModel)]="formData.cuenta_id" required #fieldCuenta="ngModel">
                                                    <ng-container *ngFor="let cuenta of cuentas">
                                                        <option value="{{cuenta._id}}">
                                                            {{cuenta.nombres}} - {{cuenta.apellidos}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                                <p class="block-tag text-right" *ngIf="fieldCuenta.invalid && (fieldCuenta.dirty || fieldCuenta.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="fieldCuenta.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>

                                            <div class="form-group" *ngIf="formData._id == null || (formData._id != null && formData.empresa_id != null)">
                                                <label for="licencia_empresa">
                                                    Empresa
                                                </label>
                                                <select name="licencia_empresa" id="licencia_empresa" required #fieldEmpresa="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.empresa_id">
                                                    <ng-container *ngFor="let empresa of empresas">
                                                        <option value="{{empresa._id}}">
                                                            {{empresa.razon_social}} - {{empresa.nit}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                                <p class="block-tag text-right" *ngIf="fieldEmpresa.invalid && (fieldEmpresa.dirty || fieldEmpresa.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="fieldEmpresa.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>
                                            
                                            <div class="form-group" *ngIf="formData._id == null || (formData._id != null && formData.plan_id != null)">
                                                <label for="licencia_plan">
                                                    Plan
                                                </label>
                                                <select name="licencia_plan" id="licencia_plan" required #fieldPlan="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.plan_id">
                                                    <ng-container *ngFor="let plan of planes">
                                                        <option value="{{plan._id}}">
                                                            {{plan.nombre}} - {{plan.precio | currency:"$"}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                                <p class="block-tag text-right" *ngIf="fieldPlan.invalid && (fieldPlan.dirty || fieldPlan.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="fieldPlan.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>

                                            <div class="form-group" *ngIf="formData._id == null || (formData._id != null && formData.estado != null)">
                                                <label for="licencia_estado">
                                                    Estado 
                                                </label>
                                                <select name="licencia_estado" id="licencia_estado" required #fieldEstado="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.estado">
                                                    <option value="VIGENTE">
                                                        Vigente
                                                    </option>
                                                    <option value="PENDIENTE_PAGO">
                                                        Pendiente Pago
                                                    </option>
                                                    <option value="CADUCADO">
                                                        Caducado
                                                    </option>
                                                    <option value="SUSPENDIDO">
                                                        Suspendido
                                                    </option>
                                                </select>
                                                <p class="block-tag text-right" *ngIf="fieldEstado.invalid && (fieldEstado.dirty || fieldEstado.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="fieldEstado.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>

                                            <div class="form-group" *ngIf="formData._id == null || (formData._id != null && formData.tipo != null)">
                                                <label for="licencia_tipo">
                                                    Tipo 
                                                </label>
                                                <select name="licencia_tipo" id="licencia_tipo" required #fieldTipo="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.tipo">
                                                    <option value="PRUEBA">
                                                        Prueba
                                                    </option>
                                                    <option value="PAGO">
                                                        Pago
                                                    </option>
                                                </select>
                                                 <p class="block-tag text-right" *ngIf="fieldTipo.invalid && (fieldTipo.dirty || fieldTipo.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="fieldTipo.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>
    
                                            <div class="form-group">
                                                <label for="licencia_inicio">Fecha Inicio</label>
                                                <div class="position-relative has-icon-left">
                                                    <input type="date" id="licencia_inicio" required #fieldFechaI="ngModel" class="form-control" [(ngModel)]="formData.fecha_inicio" placeholder="Ingrese aqui la fecha de inicio de la licencia" name="licencia_inicio">
                                                    <div class="form-control-position">
                                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <p class="block-tag text-right" *ngIf="fieldFechaI.invalid && (fieldFechaI.dirty || fieldFechaI.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="fieldFechaI.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>

                                            <div class="form-group">
                                                <label for="licencia_fin">Fecha Vencimiento</label>
                                                <div class="position-relative has-icon-left">
                                                    <input type="date" id="licencia_fin" required #fieldFechaF="ngModel" class="form-control" [(ngModel)]="formData.fecha_vencimiento" placeholder="Ingrese aqui la fecha de vencimiento de la licencia" name="licencia_fin">
                                                    <div class="form-control-position">
                                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                 <p class="block-tag text-right" *ngIf="fieldFechaF.invalid && (fieldFechaF.dirty || fieldFechaF.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="fieldFechaF.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>
    
    
                                        </div>
    
                                        <div class="form-actions right">
                                            <a class="btn btn-light mr-1" routerLink="/licencias">
                                                <i class="ft-x"></i> Cancelar
                                            </a>
                                            <button [disabled]="!formLicencias.valid" type="submit" class="btn btn-success">
                                                <i class="fa fa-check-square-o"></i> Guardar
                                            </button>
                                        </div>
                                    </form>
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- // Basic form layout section end -->
        </div>
    
    </div>