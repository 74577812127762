import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Usuario } from 'src/app/entities/usuario';
import Swal from 'sweetalert2';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(authService, router) {
        this.authService = authService;
        this.router = router;
        this.formData = new Usuario();
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.authService.closeSession();
    };
    LoginComponent.prototype.login = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        jQuery.fancybox.showLoading();
                        return [4 /*yield*/, this.authService.login(this.formData)];
                    case 1:
                        userData = _a.sent();
                        // verificamos si la consulta devolvio algun registro
                        if (userData == null) {
                            Swal({
                                type: 'error',
                                title: 'Oops...',
                                text: 'Usuario o contraseña incorrecta!'
                            });
                        }
                        else {
                            this.router.navigate(['/cuentas']);
                        }
                        jQuery.fancybox.hideLoading();
                        return [2 /*return*/];
                }
            });
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
