import { Component, OnInit } from '@angular/core';
import { CuentasService } from 'src/app/services/cuentas.service';
import { ActivatedRoute } from '@angular/router';
import { UpdatePassword } from 'src/app/entities/update-password';

import Swal from 'sweetalert2';

declare var jQuery;

@Component({
  selector: 'app-form-password-cuenta',
  templateUrl: './form-password-cuenta.component.html',
  styleUrls: ['./form-password-cuenta.component.scss']
})
export class FormPasswordCuentaComponent implements OnInit {

    formData: UpdatePassword;


    constructor(
        private cuentasService: CuentasService,
        private currentRoute: ActivatedRoute
    ) {
        // creamos una instancia para trabajar en el formulario
        this.formData = new UpdatePassword();

        // accedemos la informacion del router parent
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.formData._id = params.id;
            }
        });
    }

    ngOnInit() {
        
    }

    /**
     * Permite guardar la informacion de la cuenta
     */
    save() {
        jQuery.fancybox.showLoading();
        this.cuentasService.updatedPassword(this.formData)
            .subscribe((response) => {

                // reseteamos el formulario
                this.formData.new_pass = null;
                this.formData.renew_pass = null;

                // actualizamos la informacion local
                jQuery.fancybox.hideLoading();
                

                Swal({
                    type: 'success',
                    title: 'Hecho',
                    text: response['message']
                });
            }, (responseError)=>{
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: responseError.error['message']
                });
                jQuery.fancybox.hideLoading();
            });
    }
}
