export class Cuenta {
    public _id: string;
    public identificacion: string;
    public nombres: string;
    public apellidos: string;
    public telefono: string;
    public email: string;
    public password: string;
    public foto: string;
    public created: number;
    public updated: number;

    constructor() {
        this._id = null;
        this.identificacion = null;
        this.nombres = null;
        this.apellidos = null;
        this.telefono = null;
        this.email = null;
        this.password = null;
        this.foto = null;
        this.created = null;
        this.updated = null;
    }
}