<div class="content-wrapper">

    <div class="content-header row">
        <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">
                Cuentas
            </h3>
            <div class="row breadcrumbs-top d-inline-block">
                <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/cuentas">
                                Cuentas
                            </a>
                        </li>
                        <li class="breadcrumb-item active">
                            Lista de cuentas
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-header-right col-md-4 col-12">
            <div class="btn-group float-md-right">
                <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="icon-settings mr-1"></i>
                    Opciones
                </button>
                <div class="dropdown-menu arrow">
                    <a class="dropdown-item" routerLink="/cuentas/agregar" *ngIf="permisos['ACCOUNTS_CREATE']">
                        <i class="fa fa-plus-circle" title="añadir una nueva cuenta" aria-hidden="true"></i>
                        Nuevo
                    </a>
                    <!-- <a class="dropdown-item" href="#">
                        <i class="fa fa-cart-plus mr-1"></i>
                        Cart
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-life-ring mr-1"></i>
                        Support
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-cog mr-1"></i>
                        Settings
                    </a> -->
                </div>
            </div>
        </div>
    </div>


    <div class="content-body">

        <!-- Basic Tables start -->
        <div class="row">

            <div class="col-12">

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">
                            Lista de Cuentas
                        </h4>
                        <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                <li><a data-action="close"><i class="ft-x"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="card-content collapse show">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="card-text">
                                        Aqui se muestra todos los registros de cuentas que hay actualmente en la plataforma
                                    </p>
                                </div>
                            </div>
                            <div class="row">&nbsp;</div>
                            <div class="row">
                                <div class="col-2 offset-1">
                                    <div class="form-group position-relative has-icon-left m-0">
                                        <input type="date" class="form-control"  [(ngModel)]="filtro.fecha_inicio" (change)="filter()" name="fecha_inicio" >
                                        <div class="form-control-position">
                                            <i class="ft-calendar"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 offset-1">
                                    <div class="form-group position-relative has-icon-left m-0">
                                        <input type="date" class="form-control"  [(ngModel)]="filtro.fecha_fin" (change)="filter()" name="fecha_fin" >
                                        <div class="form-control-position">
                                            <i class="ft-calendar"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-4 offset-2">
                                    <div class="form-group position-relative has-icon-left m-0">
                                        <input type="search" class="form-control" id="iconLeft4" placeholder="Buscar" [(ngModel)]="search" name="search" >
                                        <div class="form-control-position">
                                            <i class="ft-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre</th>
                                            <th>Teléfono</th>
                                            <th>email</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cuenta of cuentasFiltradas | gridFilter: search | paginate: { itemsPerPage: 10, currentPage: page }; let i = index;">
                                            <th scope="row">
                                                {{i+1}}
                                            </th>
                                            <td>
                                                {{ cuenta.nombres }} {{ cuenta.apellidos }}
                                            </td>
                                            <td>
                                                {{ cuenta.telefono }}
                                            </td>
                                            <td>
                                                {{ cuenta.email }}
                                            </td>
                                            <td>
                                                <div class="btn-group" role="group" >
                                                    <a class="btn btn-light"
                                                        routerLink="/cuentas/{{cuenta._id}}/modificar" title="Modificar cuenta" *ngIf="permisos['ACCOUNTS_UPDATE']">
                                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                    </a>
                                                    <a class="btn btn-light" routerLink="/cuentas/{{cuenta._id}}/detalles" title="Informacion de la cuenta" *ngIf="permisos['ACCOUNTS_READ']">
                                                        <i class="fa fa-search-plus" aria-hidden="true"></i>
                                                    </a>
                                                    <button type="button" title="Eliminar cuenta" class="btn btn-danger" (click)="remove(cuenta._id, i)" *ngIf="permisos['ACCOUNTS_DELETE']">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- Basic Tables end -->

    </div>


</div>