import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { Empresa } from 'src/app/entities/empresa';
import { EmpresasService } from 'src/app/services/empresas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Cuenta } from 'src/app/entities/cuenta';
import { CuentasService } from 'src/app/services/cuentas.service';
import { Regiones } from 'src/app/constants/regiones';
import { PlanesService } from 'src/app/services/planes.service';
import { Plan } from 'src/app/entities/plan';
import { Direccion } from 'src/app/entities/direccion';

declare var jQuery;

@Component({
    selector: 'app-form-empresas',
    templateUrl: './form-empresas.component.html',
    styleUrls: ['./form-empresas.component.scss']
})
export class FormEmpresasComponent implements OnInit {

    formData: Empresa;
    cuentas: Array<Cuenta>;
    planes: Array<Plan>;
    regiones = Regiones;

    constructor(
        private empresasService: EmpresasService,
        private cuentasService: CuentasService,
        private planesService: PlanesService,
        private router: Router,
        private currentRoute: ActivatedRoute
    ) {
        this.formData = new Empresa();
        this.cuentas = [];

        // verficamos si se esta editando un formulario
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    ngOnInit() {
        this.loadCuentas();
        this.loadPlanes();
    }

    /**
     * Permite cargar la información de una empresa para editarla
     * 
     * @param _id 
     */
    private load(_id: string) {
        jQuery.fancybox.showLoading();
        this.empresasService.get(_id)
            .subscribe((response: Empresa) => {
                this.formData = response;

                // como es un campo nuevo, en caso de no estar seteado, lo seteamos
                if(!(!!this.formData.direccion)){
                    this.formData.direccion = new Direccion();
                }

                jQuery.fancybox.hideLoading();
            });
    }

    /**
     * Permite cargar las cuentas para asignarle la empresa
     */
    private loadCuentas() {
        this.cuentasService.getAll()
            .subscribe((response: Array<Cuenta>) => {
                this.cuentas = response;
            });
    }

        /**
     * Permite cargar los planes
     */
    private loadPlanes() {
        this.planesService.getAll()
            .subscribe((response: Array<Plan>) => {
                this.planes = response;
            });
    }


    changePais() {
        if(!!this.formData.direccion.pais.code){
            this.regiones.paises
                .forEach(pais => {
                    if(pais.code === this.formData.direccion.pais.code){
                        this.formData.direccion.pais.value = pais.value;
                        return;
                    }
                });
        }
    }

    changeDepartamento() {
        if(!!this.formData.direccion.departamento.code){
            this.regiones.departamentos
                .forEach(departamento => {
                    if(departamento.code === this.formData.direccion.departamento.code){
                        this.formData.direccion.departamento.value = departamento.value;
                        return;
                    }
                });
        }
    }

    changeMunicipio() {
        if(!!this.formData.direccion.municipio.code){
            this.regiones.municipios
                .forEach(municipio => {
                    if(municipio.code === this.formData.direccion.municipio.code){
                        this.formData.direccion.municipio.value = municipio.value;
                        return;
                    }
                });
        }
    }

    /**
     * Permite guardar la informacion de la empresa
     */
    save() {
        jQuery.fancybox.showLoading();
        this.empresasService.save(this.formData)
            .subscribe((response) => {
                Swal({
                    type: 'success',
                    title: 'Hecho',
                    text: response['message']
                });
                this.router.navigate(['empresas']);
            }, (responseError) => {
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: responseError.error['message']
                });
                jQuery.fancybox.hideLoading();
            })
    }
}
