import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Empresa } from '../entities/empresa';

@Injectable({
    providedIn: 'root'
})
export class EmpresasService {


    constructor(private http: HttpClient) {

    }


    /**
     * Permite obtener todos los registros 
     */
    public getAll() {
        return this.http.get<Array<Empresa>>(environment.apiUrl.admin + '/business');
    }

    /**
     * Permite obtener todos los registros 
     */
    public getByCuenta(_id: string) {
        return this.http.get<Array<Empresa>>(environment.apiUrl.admin + '/accounts/' + _id + '/business');
    }

    /**
     * Permite obtener un registro
     * @param _id 
     */
    public get(_id: string) {
        return this.http.get<Empresa>(environment.apiUrl.admin + '/business/' + _id);
    }

    /**
     * Permite guardar los datos de un registro
     * @param formData 
     */
    public save(formData: Empresa) {

        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }

        return this.edit(formData);
    }

    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData 
     */
    public add(formData: Empresa) {
        return this.http.post(environment.apiUrl.admin + '/business', formData);
    }

    /**
     * Permite guardar los cambios de un registro de usuario
     * @param formData 
     */
    public edit(formData: Empresa) {
        return this.http.put(environment.apiUrl.admin + '/business/' + formData._id, formData);
    }

    /**
     * Permite eliminar un plan
     * @param _id 
     */
    public remove(_id: string) {
        return this.http.delete(environment.apiUrl.admin + '/business/' + _id);
    }
}
