<div class="row ">
    <div class="col-md-6 col-md-offset-3" style="margin: 0 auto;">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Información de la conexion</h4>
                <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                <div class="heading-elements">
                    <ul class="list-inline mb-0">
                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                        <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                        <li><a data-action="close"><i class="ft-x"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="card-content collapse show">
                <div class="card-body">

                    <div class="card-text">
                        <p *ngIf="formData._id == null">
                            Diligencie el siguiente formulario para establecer la conexion a la base de datos de esta empresa
                        </p>
                        <p *ngIf="formData._id != null">
                            Diligencie el siguiente formulario para actualizar la conexion a la base de datos
                        </p>
                    </div>

                    <form class="form" (ngSubmit)="save()" #formConection="ngForm">
                        <div class="form-body">


                            <div class="form-group">
                                <label for="host">
                                    Dirección del Host
                                </label>
                                <div class="position-relative has-icon-left">
                                    <input type="text" id="host" class="form-control" [(ngModel)]="formData.host" placeholder="Ingrese aqui el host" name="host" #fieldHost="ngModel" required>
                                    <div class="form-control-position">
                                        <i class="fa fa-desktop" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <p class="block-tag text-right" *ngIf="fieldHost.invalid && (fieldHost.dirty || fieldHost.touched)">
                                    <small class="badge badge-default badge-danger" *ngIf="fieldHost.errors.required">
                                        Campo Requerido
                                    </small>
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="razon_social">
                                    Puerto
                                </label>
                                <div class="position-relative has-icon-left">
                                    <input type="text" id="Port" name="Port" class="form-control" [(ngModel)]="formData.port" placeholder="Ingrese aqui el puerto" #fieldPort="ngModel" required>
                                    <div class="form-control-position">
                                        <i class="fa fa-plug" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <p class="block-tag text-right" *ngIf="fieldPort.invalid && (fieldPort.dirty || fieldPort.touched)">
                                    <small class="badge badge-default badge-danger" *ngIf="fieldPort.errors.required">
                                        Campo Requerido
                                    </small>
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="username">
                                    Usuario
                                </label>
                                <div class="position-relative has-icon-left">
                                    <input type="text" id="username" class="form-control" [(ngModel)]="formData.username" placeholder="Ingrese aqui el usuario de acceso" name="username" #fieldUser="ngModel" required>
                                    <div class="form-control-position">
                                        <i class="fa fa-user-o" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <p class="block-tag text-right" *ngIf="fieldUser.invalid && (fieldUser.dirty || fieldUser.touched)">
                                    <small class="badge badge-default badge-danger" *ngIf="fieldUser.errors.required">
                                        Campo Requerido
                                    </small>
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="Password">
                                    Contraseña
                                </label>
                                <div class="position-relative has-icon-left">
                                    <input type="text" id="Password" class="form-control" [(ngModel)]="formData.password" placeholder="Ingrese aqui la contraseña de acceso" name="Password" #fieldPassword="ngModel" required>
                                    <div class="form-control-position">
                                        <i class="fa fa-lock" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <p class="block-tag text-right" *ngIf="fieldPassword.invalid && (fieldPassword.dirty || fieldPassword.touched)">
                                    <small class="badge badge-default badge-danger" *ngIf="fieldPassword.errors.required">
                                        Campo Requerido
                                    </small>
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="base_datos">
                                    Nombre de la base de datos
                                </label>
                                <div class="position-relative has-icon-left">
                                    <input type="text" id="base_datos" class="form-control" [(ngModel)]="formData.database" placeholder="Ingrese aqui el nombre de la base de datos" name="base_datos" #fieldDatabase="ngModel" required>
                                    <div class="form-control-position">
                                        <i class="fa fa-database" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <p class="block-tag text-right" *ngIf="fieldDatabase.invalid && (fieldDatabase.dirty || fieldDatabase.touched)">
                                    <small class="badge badge-default badge-danger" *ngIf="fieldDatabase.errors.required">
                                        Campo Requerido
                                    </small>
                                </p>
                            </div>


                        </div>

                        <div class="form-actions right">
                            <a class="btn btn-light mr-1" routerLink="/planes">
                                <i class="ft-x"></i> Cancelar
                            </a>
                            <button [disabled]="!formConection.valid" type="submit" class="btn btn-success">
                                <i class="fa fa-check-square-o"></i> Guardar
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>