import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Cuenta } from 'src/app/entities/cuenta';
import { CuentasService } from 'src/app/services/cuentas.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

declare var jQuery;

@Component({
  selector: 'app-form-cuentas',
  templateUrl: './form-cuentas.component.html',
  styleUrls: ['./form-cuentas.component.scss']
})
export class FormCuentasComponent implements OnInit {

    public formData: Cuenta;

    constructor(
        private cuentasService: CuentasService,
        private currentRoute: ActivatedRoute,
        private router: Router,
        private location: Location
    ) {
        // inicializamos la variable
        this.formData = new Cuenta();


        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    ngOnInit() {
        // luego cargamos en ella la informaion del usuario
        this.formData = new Cuenta();
        jQuery.fancybox.hideLoading();
    }

    /**
     * Permite cargar la informacion de la cuenta a editar
     * @param _id 
     */
    private load(_id: string){
        jQuery.fancybox.showLoading();
        this.cuentasService.get(_id)
            .subscribe((response: Cuenta)=>{
                this.formData = response;
                jQuery.fancybox.hideLoading();
            })
    }


    /**
     * Permite guardar la informacion de la cuenta
     */
    save() {
        jQuery.fancybox.showLoading();
        this.cuentasService.save(this.formData)
            .subscribe((response) => {

                // actualizamos la informacion local
                jQuery.fancybox.hideLoading();

                Swal({
                    type: 'success',
                    title: 'Hecho',
                    text: response['message']
                });

                // this.router.navigate(['cuentas']);
                this.location.back();

            }, (responseError)=>{
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: responseError.error['message']
                });
                jQuery.fancybox.hideLoading();
            });
    }

}
