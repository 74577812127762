<div class="content-wrapper">
    <div class="content-header row">

    </div>
    <div class="content-body">

        <div id="user-profile">
            <div class="row">
                <div class="col-12">
                    <div class="card profile-with-cover">
                        <div class="media profil-cover-details w-100">
                            <div class="media-left pl-2 pt-2">
                                <a href="#" class="profile-image">
                                    <img src="assets/vendors/images/portrait/small/avatar-s-8.png"
                                        class="rounded-circle img-border height-100" alt="Card image">
                                </a>
                            </div>
                            <div class="media-body pt-3 px-2">
                                <div class="row">
                                    <div class="col">
                                        <h3 class="card-title">
                                            {{empresa.razon_social}}
                                        </h3>
                                    </div>
                                    <div class="col text-right">
                                        

                                        <div class="btn-group">
                                            <button class="btn btn-primary d- dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <i class="icon-settings mr-1"></i>
                                                Opciones
                                            </button>
                                            <div class="dropdown-menu arrow">
                                                <a class="dropdown-item" routerLink="/licencias/agregar" [queryParams]="{empresa:empresa._id, cuenta: empresa.cuenta_id}" *ngIf="permisos['LICENSES_CREATE']">
                                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                    Nueva licencia
                                                </a>
                                            </div>
                                        </div>

                                        <div class="btn-group d-none d-md-block float-right ml-2" role="group" aria-label="Basic example">
                                            <button type="button" class="btn btn-success">
                                                <i class="fa fa-dashcube"></i>
                                                Mensaje
                                            </button>
                                            <button type="button" class="btn btn-success">
                                                <i class="fa fa-cog"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav class="navbar navbar-light navbar-profile align-self-end">
                            <button class="navbar-toggler d-sm-none" type="button" data-toggle="collapse"
                                aria-expanded="false" aria-label="Toggle navigation"></button>
                            <nav class="navbar navbar-expand-lg">
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav mr-auto">
                                        <li class="nav-item active">
                                            <a class="nav-link" routerLink="time-line">
                                                <i class="fa fa-line-chart"></i>
                                                Linea de Tiempo
                                                <span class="sr-only">(current)</span>
                                            </a>
                                        </li> 
                                        <li class="nav-item">
                                            <a class="nav-link" routerLink="licencias">
                                                <i class="fa fa-user-o" aria-hidden="true"></i>
                                                Cuentas
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" routerLink="licencias">
                                                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                Licencias
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" routerLink="conexion-bd">
                                                <i class="fa fa-hdd-o" aria-hidden="true"></i>
                                                Conexion BD
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" routerLink="implementacion">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                                Implementación
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <router-outlet></router-outlet>

    </div>
</div>