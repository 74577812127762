<!-- Basic Tables start -->
<div class="row">

        <div class="col-12">
    
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        Lista de Licencias
                    </h4>
                    <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                    <div class="heading-elements">
                        <ul class="list-inline mb-0">
                            <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                            <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                            <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                            <li><a data-action="close"><i class="ft-x"></i></a></li>
                        </ul>
                    </div>
                </div>
    
                <div class="card-content collapse show">
    
                    <div class="card-body">
                        <p class="card-text">
                            Aqui se muestra todos los registros de licencias que hay actualmente en la plataforma de la Empresa
                        </p>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Plan</th>
                                        <th>Fecha de Inicio</th>
                                        <th>Fecha de Vencimiento</th>
                                        <th>Estado</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let licencia of licencias | paginate: { itemsPerPage: 10, currentPage: page }; let i = index;">
                                        <th scope="row">
                                            {{licencia.consecutivo}}
                                        </th>
                                        <td>
                                            {{getLabelPlan(licencia.plan_id)}}
                                        </td>
                                        <td>
                                            {{licencia.fecha_inicio | date}}
                                        </td>
                                        <td>
                                            {{licencia.fecha_vencimiento | date}}
                                        </td>
                                        <td>
                                            {{licencia.estado}}
                                        </td>
                                        <td>
                                            <div class="btn-group" role="group" >
                                                <a class="btn btn-light " routerLink="/licencias/{{licencia._id}}/detalles" *ngIf="permisos['LICENSES_READ']">
                                                    <i class="fa fa-search-plus" aria-hidden="true"></i>
                                                </a>
                                                <a class="btn btn-light "
                                                    routerLink="/licencias/{{licencia._id}}/modificar" *ngIf="permisos['LICENSES_UPDATE']">
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </a>
                                                <button type="button" class="btn btn-danger" (click)="remove(licencia._id, i)" *ngIf="permisos['LICENSES_DELETE']">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                        </div>
                    </div>
    
                </div>
            </div>
    
    
        </div>
    </div>
    <!-- Basic Tables end -->