<!-- cabecera -->
<app-nav *appRequireAuth
    class="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-semi-dark navbar-shadow"></app-nav>

<!-- Menu principal *appRequireAuth-->
<app-menu *appRequireAuth class="main-menu menu-fixed menu-dark menu-accordion menu-shadow"
    data-scroll-to-active="true"></app-menu>

<!-- content aplication -->
<div class="app-content content  bg-grey bg-lighten-2">
    <router-outlet></router-outlet>
</div>


<!-- Pie de pagina-->
<footer class="footer footer-static footer-light navbar-border">
    <p class="clearfix blue-grey lighten-2 text-sm-center mb-0">
        <span class="float-md-left d-block d-md-inline-block">Copyright &copy; 2019
            <a class="text-bold-800 grey darken-2" href="https://www.kuppel.co/" target="_blank">Kuppel </a>, Todos los
            derechos reservados.
        </span>
        <span class="float-md-right d-block d-md-inline-blockd-none d-lg-block">
            Hecho con <i class="ft-heart pink"></i> por Kuppel
        </span>
    </p>
</footer>
