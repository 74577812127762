import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CuentasService = /** @class */ (function () {
    function CuentasService(http) {
        this.http = http;
    }
    /**
     * Permite obtener todos los registros
     */
    CuentasService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl.admin + '/accounts');
    };
    /**
     * Permite obtener un registro
     * @param _id
     */
    CuentasService.prototype.get = function (_id) {
        return this.http.get(environment.apiUrl.admin + '/accounts/' + _id);
    };
    /**
     * Permite guardar los datos de un registro
     * @param formData
     */
    CuentasService.prototype.save = function (formData) {
        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }
        return this.edit(formData);
    };
    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData
     */
    CuentasService.prototype.add = function (formData) {
        return this.http.post(environment.apiUrl.admin + '/accounts', formData);
    };
    /**
     * Permite guardar los cambios de un registro
     * @param formData
     */
    CuentasService.prototype.edit = function (formData) {
        return this.http.put(environment.apiUrl.admin + '/accounts/' + formData._id, formData);
    };
    /**
     * Permite eliminar un plan
     * @param _id
     */
    CuentasService.prototype.remove = function (_id) {
        return this.http.delete(environment.apiUrl.admin + '/accounts/' + _id);
    };
    /**
     * Permite actualizar la contraseña de una cuenta
     */
    CuentasService.prototype.updatedPassword = function (formData) {
        return this.http.put(environment.apiUrl.admin + '/accounts/' + formData._id + '/update-password', formData);
    };
    CuentasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CuentasService_Factory() { return new CuentasService(i0.ɵɵinject(i1.HttpClient)); }, token: CuentasService, providedIn: "root" });
    return CuentasService;
}());
export { CuentasService };
