import { Component, OnInit } from '@angular/core';
import { Empresa } from 'src/app/entities/empresa';
import { EmpresasService } from 'src/app/services/empresas.service';
import { ActivatedRoute } from '@angular/router';

declare var jQuery;

@Component({
  selector: 'app-empresas-cuenta',
  templateUrl: './empresas-cuenta.component.html',
  styleUrls: ['./empresas-cuenta.component.scss']
})
export class EmpresasCuentaComponent implements OnInit {

    empresas: Array<Empresa>;
    search: string;
    page: number;

    constructor(
        private empresasService: EmpresasService,
        private currentRoute: ActivatedRoute
    ) {
        this.empresas = [];
        this.search = null;
        this.page = 1;
    }

    ngOnInit() {
        // accedemos la informacion del router parent
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    /**
     * permite cargar las empresas
     */
    private load(_id: string) {
        jQuery.fancybox.showLoading();
        this.empresasService.getByCuenta(_id)
            .subscribe((response: Array<Empresa>) => {
                this.empresas = response;
                jQuery.fancybox.hideLoading();
            })
    }

}
