<div class="content-wrapper">
    <div class="content-header row"></div>
    <div class="content-body">
        <section class="flexbox-container">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="col-md-4 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 m-0">
                        <div class="card-header border-0">
                            <div class="card-title text-center">
                                <div class="p-1">
                                    <img src="https://cdn.kuppel.co/brand/logo-kuppel-negro.png" alt="branding logo" class="logo">
                                </div>
                            </div>
                            <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                <span>Ingresar </span>
                            </h6>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <form class="form-horizontal form-simple" (ngSubmit)="login()" >
                                    <fieldset class="form-group position-relative has-icon-left mb-0">
                                        <input type="text" class="form-control form-control-lg input-lg" name="username" [(ngModel)]="formData.username" id="user-name" placeholder="Ingrese su usuario" required>
                                        <div class="form-control-position">
                                            <i class="ft-user"></i>
                                        </div>
                                    </fieldset>
                                    <fieldset class="form-group position-relative has-icon-left">
                                        <input type="password" class="form-control form-control-lg input-lg" name="password" [(ngModel)]="formData.password" id="user-password" placeholder="Ingrese su contraseña" required>
                                        <div class="form-control-position">
                                            <i class="fa fa-key"></i>
                                        </div>
                                    </fieldset>
                                    <button type="submit" class="btn btn-info btn-lg btn-block">
                                        <i class="ft-unlock"></i>
                                        Iniciar Sesión
                                    </button>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>