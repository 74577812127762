import { Component, OnInit } from '@angular/core';
import { EmpresasService } from 'src/app/services/empresas.service';
import { ActivatedRoute } from '@angular/router';
import { Empresa } from 'src/app/entities/empresa';

declare var jQuery;

@Component({
    selector: 'app-view-empresas',
    templateUrl: './view-empresas.component.html',
    styleUrls: ['./view-empresas.component.scss']
})
export class ViewEmpresasComponent implements OnInit {

    empresa: Empresa;
    permisos: any;

    constructor(
        private empresasService: EmpresasService,
        private currentRoute: ActivatedRoute
    ) { 
        this.empresa = new Empresa();
        this.permisos = JSON.parse(localStorage.userLogged).permisos;
        // verficamos si se esta editando un formulario
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    ngOnInit() {
    }

    /**
     * Permite cargar la información de una empresa para editarla
     * 
     * @param _id 
     */
    private load(_id: string) {
        jQuery.fancybox.showLoading();
        this.empresasService.get(_id)
            .subscribe((response: Empresa) => {
                this.empresa = response;
                jQuery.fancybox.hideLoading();
            });
    }
}
