/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./page-not-found.component";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "content-header row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 25, "section", [["class", "flexbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 24, "div", [["class", "col-12 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 23, "div", [["class", "col-md-4 col-10 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "card-header bg-transparent border-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h2", [["class", "error-code text-center mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h3", [["class", "text-uppercase text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Page Not Found !"])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "col-12 col-sm-12 col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["class", "btn btn-primary btn-block"], ["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "ft-home"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ir al Inicio "])), (_l()(), i1.ɵeld(18, 0, null, null, 10, "div", [["class", "card-footer bg-transparent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 8, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "a", [["class", "btn btn-social-icon mr-1 mb-1 btn-outline-facebook"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "span", [["class", "fa fa-facebook"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "a", [["class", "btn btn-social-icon mr-1 mb-1 btn-outline-twitter"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "span", [["class", "fa fa-twitter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "a", [["class", "btn btn-social-icon mr-1 mb-1 btn-outline-linkedin"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "span", [["class", "fa fa-linkedin font-medium-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "a", [["class", "btn btn-social-icon mr-1 mb-1 btn-outline-github"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, "span", [["class", "fa fa-github font-medium-4"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/dashboard"; _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 15).target; var currVal_1 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_0, currVal_1); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.PageNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i4.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
