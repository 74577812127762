import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Plan } from '../entities/plan';

@Injectable({
    providedIn: 'root'
})
export class PlanesService {

    constructor(private http: HttpClient) {

    }

    /**
     * Permite obtener todos los registros 
     */
    public getAll() {
        return this.http.get<Array<Plan>>(environment.apiUrl.admin + '/plans');
    }

    /**
     * Permite obtener un registro
     * @param _id 
     */
    public get(_id: string) {
        return this.http.get<Plan>(environment.apiUrl.admin + '/plans/' + _id);
    }

    /**
     * Permite guardar los datos de un registro
     * @param formData 
     */
    public save(formData: Plan) {

        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }

        return this.edit(formData);
    }

    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData 
     */
    public add(formData: Plan) {
        return this.http.post(environment.apiUrl.admin + '/plans', formData);
    }

    /**
     * Permite guardar los cambios de un registro de usuario
     * @param formData 
     */
    public edit(formData: Plan) {
        return this.http.put(environment.apiUrl.admin + '/plans/' + formData._id, formData);
    }

    /**
     * Permite eliminar un plan
     * @param _id 
     */
    public remove(_id: string) {
        return this.http.delete(environment.apiUrl.admin + '/plans/' + _id);
    }
}
