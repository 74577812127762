export class Direccion {
    pais: {
        "code": string,
        "value": string
    };
    departamento: {
        "code": string,
        "value": string
    };
    municipio: {
        "code": string,
        "value": string
    };
    calle: string;


    constructor(){
        this.pais = {
            "code": "CO",
            "value": "Colombia"
        };
        this.departamento = {
            "code": null,
            "value": null
        };
        this.municipio = {
            "code": null,
            "value": null
        };
        this.calle = null;
    }
}
