import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PlanesService } from 'src/app/services/planes.service';
import { LicenciasService } from 'src/app/services/licencias.service';
import { Licencia } from 'src/app/entities/licencia';
import { Plan } from 'src/app/entities/plan';
import { Router, ActivatedRoute } from '@angular/router';
import { Empresa } from 'src/app/entities/empresa';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Cuenta } from 'src/app/entities/cuenta';
import { CuentasService } from 'src/app/services/cuentas.service';

import * as moment from 'moment';
import Swal from 'sweetalert2';


declare var jQuery;

@Component({
    selector: 'app-form-licencias',
    templateUrl: './form-licencias.component.html',
    styleUrls: ['./form-licencias.component.scss']
})
export class FormLicenciasComponent implements OnInit {

    cuentas: Array<Cuenta>;
    formData: Licencia;
    empresas: Array<Empresa>;
    planes: Array<Plan>;

    constructor(
        private empresaService: EmpresasService,
        private planService: PlanesService,
        private licenciasService: LicenciasService,
        private cuentasService: CuentasService,
        private router: Router,
        private currentRoute: ActivatedRoute,
        private location: Location
    ) {
        this.empresas = [];
        this.planes = [];
        this.cuentas = [];
        this.formData = new Licencia();
    }

    ngOnInit() {

        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });

        // verificamos si hay query params en la url (?empresa=sdjkasd&cuenta=klasdf)
        this.currentRoute.queryParams.subscribe(params => {
            if (typeof params.empresa !== 'undefined') {
                this.formData.empresa_id = params.empresa;
            }

            if (typeof params.cuenta !== 'undefined') {
                this.formData.cuenta_id = params.cuenta;
            }
        });

        this.loadEmpresas();
        this.loadPlanes();
        this.loadCuentas();
    }

    /**
     * Permite cargar la informacion de la licencia para ser editado
     * @param _id 
     */
    private load(_id: string) {
        this.licenciasService.get(_id)
            .subscribe((response: Licencia) => {
                this.formData = response;
                this.formData.fecha_inicio = moment.unix(Number(this.formData.fecha_inicio)).format(moment.HTML5_FMT.DATE);
                this.formData.fecha_vencimiento = moment.unix(Number(this.formData.fecha_vencimiento)).format(moment.HTML5_FMT.DATE);
            });
    }

    /**
     * Permite cargar las empresas
     */
    private loadEmpresas() {
        this.empresaService.getAll()
            .subscribe((response: Array<Empresa>) => {
                this.empresas = response;
            });
    }

    /**
     * Permite cargar los planes
     */
    private loadPlanes() {
        this.planService.getAll()
            .subscribe((response: Array<Plan>) => {
                this.planes = response;
            });
    }

    /**
     * Permite cargar todas las cuentas
     */
    private loadCuentas() {
        this.cuentasService.getAll()
            .subscribe((response: Array<Cuenta>) => {
                this.cuentas = response;
            });
    }



    /**
     * Permite guardar los cambios
     */
    public save() {
        jQuery.fancybox.showLoading();
        this.formData.fecha_inicio = moment(this.formData.fecha_inicio, moment.HTML5_FMT.DATE).unix();
        this.formData.fecha_vencimiento = moment(this.formData.fecha_vencimiento, moment.HTML5_FMT.DATE).second(59).minute(59).hour(23).unix();
        this.licenciasService.save(this.formData)
            .subscribe((response) => {
                // this.router.navigate(['licencias']);
                this.location.back();
                Swal({
                    type: 'success',
                    title: 'Hecho',
                    text: "Se ha guardado la informacion correctamente"
                });
                jQuery.fancybox.hideLoading();
            });
    }

}
