import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Plan } from 'src/app/entities/plan';
import { PlanesService } from 'src/app/services/planes.service';
import { LicenciasService } from 'src/app/services/licencias.service';
import { Empresa } from 'src/app/entities/empresa';
import { EmpresasService } from 'src/app/services/empresas.service';
import Swal from 'sweetalert2';
import { Filter } from 'src/app/entities/filter';
var LicenciasComponent = /** @class */ (function () {
    function LicenciasComponent(empresaService, planesService, licenciasService) {
        this.empresaService = empresaService;
        this.planesService = planesService;
        this.licenciasService = licenciasService;
        this.licencias = [];
        this.filtro = new Filter();
        this.page = 1;
        this.permisos = JSON.parse(localStorage.userLogged).permisos;
    }
    LicenciasComponent.prototype.ngOnInit = function () {
        this.load();
    };
    /**
     * Permite cargar las licencias
     */
    LicenciasComponent.prototype.load = function () {
        var _this = this;
        this.licenciasService.getAll()
            .subscribe(function (licencias) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var empresas, planes, _i, licencias_1, licencia, _a, empresas_1, empresa, _b, planes_1, plan;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.empresaService.getAll().toPromise()];
                    case 1:
                        empresas = _c.sent();
                        return [4 /*yield*/, this.planesService.getAll().toPromise()];
                    case 2:
                        planes = _c.sent();
                        for (_i = 0, licencias_1 = licencias; _i < licencias_1.length; _i++) {
                            licencia = licencias_1[_i];
                            licencia.empresa = new Empresa();
                            for (_a = 0, empresas_1 = empresas; _a < empresas_1.length; _a++) {
                                empresa = empresas_1[_a];
                                if (empresa._id == licencia.empresa_id) {
                                    licencia.empresa = empresa;
                                    break;
                                }
                            }
                            licencia.plan = new Plan();
                            for (_b = 0, planes_1 = planes; _b < planes_1.length; _b++) {
                                plan = planes_1[_b];
                                if (plan._id == licencia.plan_id) {
                                    licencia.plan = plan;
                                    break;
                                }
                            }
                        }
                        this.licencias = licencias;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    /**
     * Permite eliminar un plan registrado
      *
      * @param _id identificador del registro
      * @param index identificador de la posicion en el array
      */
    LicenciasComponent.prototype.remove = function (_id, index) {
        var _this = this;
        Swal({
            type: 'warning',
            title: 'Desea eliminar la licencia seleccionada',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(function (succ) {
            if (succ.value) {
                jQuery.fancybox.showLoading();
                _this.licenciasService.remove(_id)
                    .subscribe(function () {
                    _this.licencias.splice(index, 1);
                    jQuery.fancybox.hideLoading();
                });
            }
        });
    };
    return LicenciasComponent;
}());
export { LicenciasComponent };
