import { Component, OnInit } from '@angular/core';
import { CuentaAdministrador } from 'src/app/entities/cuenta-administrador';
import { CuentasAdministradoresService } from 'src/app/services/cuentas-administradores.service';
import swal from 'sweetalert2';

declare var jQuery;

@Component({
    selector: 'app-cuentas-administradores',
    templateUrl: './cuentas-administradores.component.html',
    styleUrls: ['./cuentas-administradores.component.scss']
})
export class CuentasAdministradoresComponent implements OnInit {

    cuentasAdmin: Array<CuentaAdministrador>;
    search: string;
    page: number;

    constructor(
        private cuentasService: CuentasAdministradoresService
    ) {
        this.cuentasAdmin = [];
        this.search = null;
        this.page = 1;
    }

    ngOnInit() {
        this.load();
    }

    private load() {
        // jQuery.fancybox.showLoading();
        this.cuentasService.getAll()
            .subscribe((response: []/* Array<Usuario> */) => {
                this.cuentasAdmin = response;
                // jQuery.fancybox.hideLoading();
            })
    }

    /**
     * Permite eliminar un registro
     * @param _id identificador del registro
     * @param i key o index del registro
     */
    public remove(_id: string, i: number) {
        swal({
            title: 'Está seguro que desea eliminar esta cuenta?',
            showConfirmButton: true,
            showCancelButton: true,
            type: 'question'
        }).then(succ => {
            if (succ.value) {
                this.cuentasService.remove(_id)
                    .subscribe(succ => {
                        this.cuentasAdmin.splice(i,1);
                        swal({
                            title: 'Se eliminó correctamente',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    });
            }
        });
    }

}
