import { Component, OnInit } from '@angular/core';
import { Plan } from 'src/app/entities/plan';
import { PlanesService } from 'src/app/services/planes.service';
import { LicenciasService } from 'src/app/services/licencias.service';
import { Empresa } from 'src/app/entities/empresa';
import { EmpresasService } from 'src/app/services/empresas.service';
import Swal from 'sweetalert2';
import { ViewLicencia } from 'src/app/entities/view-licencia';
import { Filter } from 'src/app/entities/filter';






declare var jQuery;

@Component({
    selector: 'app-licencias',
    templateUrl: './licencias.component.html',
    styleUrls: ['./licencias.component.scss']
})
export class LicenciasComponent implements OnInit {

    licencias: Array<ViewLicencia>;
    filtro: Filter;
    page: number;
    permisos: any;

    constructor(
        private empresaService: EmpresasService,
        private planesService: PlanesService,
        private licenciasService: LicenciasService
    ) {
        this.licencias = [];
        this.filtro = new Filter();
        this.page = 1;
        this.permisos = JSON.parse(localStorage.userLogged).permisos;
    }

    ngOnInit() {
        this.load();
    }

    /**
     * Permite cargar las licencias
     */
    private load() {
        this.licenciasService.getAll()
            .subscribe(async (licencias: Array<ViewLicencia>) => {

                // cargamos las empresas
                const empresas = await this.empresaService.getAll().toPromise();
                const planes = await this.planesService.getAll().toPromise();

                for(let licencia of licencias ){

                    licencia.empresa = new Empresa();
                    for(let empresa of empresas){
                        if(empresa._id == licencia.empresa_id){
                            licencia.empresa = empresa;
                            break;
                        }
                    }

                    licencia.plan = new Plan();
                    for(let plan of planes){
                        if(plan._id == licencia.plan_id){
                            licencia.plan = plan;
                            break;
                        }
                    }

                }

                this.licencias = licencias;
            })
    }

    /**
     * Permite eliminar un plan registrado
      * 
      * @param _id identificador del registro
      * @param index identificador de la posicion en el array
      */
    public remove(_id: string, index: number) {
        Swal({
            type: 'warning',
            title: 'Desea eliminar la licencia seleccionada',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(succ => {
            if (succ.value) {
                jQuery.fancybox.showLoading();
                this.licenciasService.remove(_id)
                    .subscribe(() => {
                        this.licencias.splice(index, 1);
                        jQuery.fancybox.hideLoading();
                    });
            } 
        });
    }

}
