import { Component, OnInit } from '@angular/core';
import { Modulo } from '../../entities/modulo';
import { ModulosService } from '../../services/modulos.service';
import { Route,ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common'
import swal from 'sweetalert2';
@Component({
  selector: 'app-form-modulos',
  templateUrl: './form-modulos.component.html',
  styleUrls: ['./form-modulos.component.scss']
})
export class FormModulosComponent implements OnInit {
	public formData:Modulo;
	constructor(
		private modulo:ModulosService,
		private currentRoute:ActivatedRoute,
		private location:Location
	){
		this.formData = new Modulo();
		this.currentRoute.params.subscribe(params=>{
			if(typeof params.id!=="undefined"){
				this.load(params.id);
			}
		});
	}
	load(id:String){
		this.modulo.getById(id).subscribe((modulo:Modulo)=>this.formData=modulo);
	}
	ngOnInit() {

	}
	save(){
		this.modulo.save(this.formData).subscribe(succ=>{
			swal({
				text:'Se guardó la informacion correctamente',
				title:'Hecho',
				type:'success'
			});
			this.location.back();
		});
	}
}
