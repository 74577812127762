import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PlanesComponent } from './pages/planes/planes.component';
import { FormPlanesComponent } from './pages/form-planes/form-planes.component';
import { ViewPlanesComponent } from './pages/view-planes/view-planes.component';
import { LicenciasComponent } from './pages/licencias/licencias.component';
import { FormLicenciasComponent } from './pages/form-licencias/form-licencias.component';
import { CuentasComponent } from './pages/cuentas/cuentas.component';
import { FormCuentasComponent } from './pages/form-cuentas/form-cuentas.component';
import { EmpresasCuentaComponent } from './pages/empresas-cuenta/empresas-cuenta.component';
import { LicenciasCuentaComponent } from './pages/licencias-cuenta/licencias-cuenta.component';
import { LicenciasEmpresaComponent } from './pages/licencias-empresa/licencias-empresa.component';
import { ViewCuentasComponent } from './pages/view-cuentas/view-cuentas.component';
import { FormPasswordCuentaComponent } from './pages/form-password-cuenta/form-password-cuenta.component';
import { EmpresasComponent } from './pages/empresas/empresas.component';
import { FormEmpresasComponent } from './pages/form-empresas/form-empresas.component';
import { ViewEmpresasComponent } from './pages/view-empresas/view-empresas.component';
import { ImplementacionesEmpresasComponent } from './pages/implementaciones-empresas/implementaciones-empresas.component';
import { FormConexionesBdComponent } from './pages/form-conexiones-bd/form-conexiones-bd.component';
import { FormModulosComponent } from './pages/form-modulos/form-modulos.component';
import { ModulosComponent } from './pages/modulos/modulos.component';
import { ViewModulosComponent } from './pages/view-modulos/view-modulos.component';
import { FormFuncionalidadModuloComponent } from './pages/form-funcionalidad-modulo/form-funcionalidad-modulo.component';
import { ViewLicenciasComponent } from './pages/view-licencias/view-licencias.component';
import { CuentasAdministradoresComponent } from './pages/cuentas-administradores/cuentas-administradores.component';
import { FormCuentasAdminComponent } from './pages/form-cuentas-admin/form-cuentas-admin.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'cuentas-administradores', component: CuentasAdministradoresComponent },
    { path: 'cuentas-administradores/agregar', component: FormCuentasAdminComponent },
    { path: 'cuentas-administradores/:id/modificar', component: FormCuentasAdminComponent },
    { path: 'cuentas', component: CuentasComponent },
    { path: 'cuentas/agregar', component: FormCuentasComponent },
    { path: 'cuentas/:id/modificar', component: FormCuentasComponent },
    {
        path: 'cuentas/:id/detalles',
        component: ViewCuentasComponent,
        children: [
            { path: 'empresas', component: EmpresasCuentaComponent },
            { path: 'licencias', component: LicenciasCuentaComponent },
            { path: 'cambiar-contrasena', component: FormPasswordCuentaComponent },
            { path: '', redirectTo: 'empresas', pathMatch: 'full' },
        ]
    },
    { path: 'empresas', component: EmpresasComponent },
    { path: 'empresas/agregar', component: FormEmpresasComponent },
    { path: 'empresas/:id/modificar', component: FormEmpresasComponent },
    {
        path: 'empresas/:id/detalles',
        component: ViewEmpresasComponent,
        children: [
            // { path: 'cuentas', component: },
            { path: 'licencias', component: LicenciasEmpresaComponent },
            { path: 'conexion-bd', component: FormConexionesBdComponent },
            { path: '', redirectTo: 'licencias', pathMatch: 'full' },
            { path: 'implementacion', component: ImplementacionesEmpresasComponent },
        ]
    },
    { path: 'planes', component: PlanesComponent },
    { path: 'planes/add', component: FormPlanesComponent },
    { path: 'planes/:id/edit', component: FormPlanesComponent },
    { path: 'planes/:id/view', component: ViewPlanesComponent },
    { path: 'licencias', component: LicenciasComponent },
    { path: 'licencias/agregar', component: FormLicenciasComponent },
    { path: 'licencias/:id/modificar', component: FormLicenciasComponent },
    { path: 'licencias/:id/detalles', component: ViewLicenciasComponent },
    { path: 'modulos', component: ModulosComponent },
    { path: 'modulos/agregar', component: FormModulosComponent },
    { path: 'modulos/:id/modificar', component: FormModulosComponent },
    { path: 'modulos/:id/detalles', component: ViewModulosComponent },
    { path: 'modulos/:modulo/detalles/funcionalidades', component: FormFuncionalidadModuloComponent },
    { path: 'modulos/:modulo/detalles/funcionalidades/:id', component: FormFuncionalidadModuloComponent },
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
