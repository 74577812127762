<!-- Basic Tables start -->
<div class="row">

    <div class="col-12">

        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    Lista de Empresas
                </h4>
                <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                <div class="heading-elements">
                    <ul class="list-inline mb-0">
                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                        <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                        <li><a data-action="close"><i class="ft-x"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="card-content collapse show">

                <div class="card-body">
                    <div class="row">
                        <div class="col-8">
                            <p class="card-text">
                                Aqui se muestra todos los registros de empresas que posee la cuenta  o a los que la cuenta tiene acceso
                            </p>
                        </div>
                        <div class="col-4">
                            <div class="form-group position-relative has-icon-left m-0">
                                <input type="search" class="form-control" id="iconLeft4" placeholder="Buscar" [(ngModel)]="search" name="search" >
                                <div class="form-control-position">
                                    <i class="ft-search"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nit</th>
                                    <th>Razon Social</th>
                                    <th>Contacto</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let empresa of empresas | gridFilter: search | paginate: { itemsPerPage: 10, currentPage: page }; let i = index;">
                                    <th scope="row">
                                        {{i+1}}
                                    </th>
                                    <td>
                                        {{empresa.nit}}
                                    </td>
                                    <td>
                                        {{empresa.razon_social}}
                                    </td>
                                    <td>
                                        {{empresa.contacto}}
                                    </td>
                                    <td>
                                        <div class="btn-group" role="group" >
                                            <a class="btn btn-light" routerLink="/empresas/{{empresa._id}}/modificar"> 
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                            </a>
                                            <a class="btn btn-light" routerLink="/empresas/{{empresa._id}}/detalles"> 
                                                <i class="fa fa-search-plus" aria-hidden="true"></i>
                                            </a>
                                            <!-- <button type="button" class="btn btn-danger" (click)="remove(empresa._id, i)">
                                                <i class="fa fa-trash"></i>
                                            </button> -->
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>

            </div>
        </div>
        
        
    </div>
</div>
<!-- Basic Tables end -->