import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cuenta } from '../entities/cuenta';
import { UpdatePassword } from '../entities/update-password';

@Injectable({
    providedIn: 'root'
})
export class CuentasService {


    constructor(private http: HttpClient) {

    }


    /**
     * Permite obtener todos los registros 
     */
    public getAll() {
        return this.http.get<Array<Cuenta>>(environment.apiUrl.admin + '/accounts');
    }

    /**
     * Permite obtener un registro
     * @param _id 
     */
    public get(_id: string) {
        return this.http.get<Cuenta>(environment.apiUrl.admin + '/accounts/' + _id);
    }

    /**
     * Permite guardar los datos de un registro
     * @param formData 
     */
    public save(formData: Cuenta) {

        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }

        return this.edit(formData);
    }

    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData 
     */
    public add(formData: Cuenta) {
        return this.http.post(environment.apiUrl.admin + '/accounts', formData);
    }

    /**
     * Permite guardar los cambios de un registro
     * @param formData 
     */
    public edit(formData: Cuenta) {
        return this.http.put(environment.apiUrl.admin + '/accounts/' + formData._id, formData);
    }

    /**
     * Permite eliminar un plan
     * @param _id 
     */
    public remove(_id: string) {
        return this.http.delete(environment.apiUrl.admin + '/accounts/' + _id);
    }

    /**
     * Permite actualizar la contraseña de una cuenta
     */
    public updatedPassword(formData: UpdatePassword){
        return this.http.put(environment.apiUrl.admin + '/accounts/' + formData._id + '/update-password', formData);
    }
}
