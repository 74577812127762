<div class="content-wrapper">
    <div class="content-header row">
        <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">
                Planes
            </h3>
            <div class="row breadcrumbs-top d-inline-block">
                <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/planes">
                                Planes
                            </a>
                        </li>
                        <li class="breadcrumb-item active">
                            Lista de planes
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-header-right col-md-4 col-12">
            <div class="btn-group float-md-right">
                <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="icon-settings mr-1"></i>
                    Opciones
                </button>
                <div class="dropdown-menu arrow">
                    <a class="dropdown-item" routerLink="/planes/add" *ngIf="permisos['PLANS_CREATE']">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        Nuevo Plan
                    </a>
                </div>
            </div>
        </div>
    </div>


    <div class="content-body">

        <!-- Basic Tables start -->
        <div class="row">

            <div class="col-12">

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">
                            Lista de planes
                        </h4>
                        <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                <li><a data-action="close"><i class="ft-x"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="card-content collapse show">

                        <div class="card-body">
                            
                            <div class="row">
                                <div class="col-8">
                                    <p class="card-text">
                                        Aqui se muestra todos los registros de planes que hay actualmente en la plataforma
                                    </p>
                                </div>
                                <div class="col-4">
                                    <div class="form-group position-relative has-icon-left m-0">
                                        <input type="search" class="form-control" id="iconLeft4" placeholder="Buscar" [(ngModel)]="search" name="search" >
                                        <div class="form-control-position">
                                            <i class="ft-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre</th>
                                            <th>Precio</th>
                                            <th>Dias de prueba</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let plan of planes | gridFilter: search; let i = index">
                                            <th scope="row">{{i+1}}</th>
                                            <td>
                                                {{plan.nombre}}
                                            </td>
                                            <td>
                                                {{plan.precio | currency:'$'}}
                                            </td>
                                            <td>
                                                {{plan.dias_prueba}}
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <a class="btn btn-light mr-1" title="Modificar plan"
                                                        routerLink="/planes/{{plan._id}}/edit" *ngIf="permisos['PLANS_UPDATE']">
                                                        <i class="fa fa-pencil-square-o"  aria-hidden="true"></i>
                                                    </a>
                                                    <a class="btn btn-light mr-1"  title="Ver información del plan"
                                                        routerLink="/planes/{{plan._id}}/view" *ngIf="permisos['PLANS_READ']">
                                                        <i class="fa fa-search-plus" aria-hidden="true"></i>
                                                    </a>
                                                    <button type="submit" class="btn btn-danger" title="Eliminar plan" (click)="remove(plan._id, i)" *ngIf="permisos['PLANS_DELETE']">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- Basic Tables end -->

    </div>


</div>