export class CuentaAdministrador {
    public _id: string;
    public identificacion: string;
    public nombres: string;
    public apellidos: string;
    public telefono: string;
    public email: string;
    public username: string;
    public password: string;
    public repassword: string;
    public foto: string;
    public created: number;
    public updated: number;
    public permisos: any;

    constructor() {
        this._id = null;
        this.identificacion = null;
        this.nombres = null;
        this.apellidos = null;
        this.telefono = null;
        this.email = null;
        this.username = null;
        this.password = null;
        this.repassword = null;
        this.foto = null;
        this.created = null;
        this.updated = null;
        this.permisos = null;

        this.permisos = {
            "DASHBOARD_READ": true,
    
            "ADMIN_ACCOUNTS_CREATE": false,
            "ADMIN_ACCOUNTS_READ": false,
            "ADMIN_ACCOUNTS_UPDATE": false,
            "ADMIN_ACCOUNTS_DELETE": false,
    
            "ACCOUNTS_CREATE": false,
            "ACCOUNTS_READ": true,
            "ACCOUNTS_UPDATE": false,
            "ACCOUNTS_DELETE": false,
    
            "BUSINESS_CREATE": false,
            "BUSINESS_READ": true,
            "BUSINESS_UPDATE": false,
            "BUSINESS_DELETE": false,
    
            "PLANS_CREATE": false,
            "PLANS_READ": true,
            "PLANS_UPDATE": false,
            "PLANS_DELETE": false,
    
            "LICENSES_CREATE": false,
            "LICENSES_READ": true,
            "LICENSES_UPDATE": false,
            "LICENSES_DELETE": false,

            "MODULES_CREATE": false,
            "MODULES_READ": true,
            "MODULES_UPDATE": false,
            "MODULES_DELETE": false
        };
    }
}