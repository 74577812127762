import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EpaycoTransaccionesService {

    constructor(
        private http: HttpClient
    ) {

    }

    public getByLicencia(id: string) {
        return this.http.get(environment.apiUrl.admin + '/licenses/' + id + '/transacctions');
    }

}
