import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EpaycoTransaccionesService = /** @class */ (function () {
    function EpaycoTransaccionesService(http) {
        this.http = http;
    }
    EpaycoTransaccionesService.prototype.getByLicencia = function (id) {
        return this.http.get(environment.apiUrl.admin + '/licenses/' + id + '/transacctions');
    };
    EpaycoTransaccionesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EpaycoTransaccionesService_Factory() { return new EpaycoTransaccionesService(i0.ɵɵinject(i1.HttpClient)); }, token: EpaycoTransaccionesService, providedIn: "root" });
    return EpaycoTransaccionesService;
}());
export { EpaycoTransaccionesService };
