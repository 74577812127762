import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PlanesService = /** @class */ (function () {
    function PlanesService(http) {
        this.http = http;
    }
    /**
     * Permite obtener todos los registros
     */
    PlanesService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl.admin + '/plans');
    };
    /**
     * Permite obtener un registro
     * @param _id
     */
    PlanesService.prototype.get = function (_id) {
        return this.http.get(environment.apiUrl.admin + '/plans/' + _id);
    };
    /**
     * Permite guardar los datos de un registro
     * @param formData
     */
    PlanesService.prototype.save = function (formData) {
        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }
        return this.edit(formData);
    };
    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData
     */
    PlanesService.prototype.add = function (formData) {
        return this.http.post(environment.apiUrl.admin + '/plans', formData);
    };
    /**
     * Permite guardar los cambios de un registro de usuario
     * @param formData
     */
    PlanesService.prototype.edit = function (formData) {
        return this.http.put(environment.apiUrl.admin + '/plans/' + formData._id, formData);
    };
    /**
     * Permite eliminar un plan
     * @param _id
     */
    PlanesService.prototype.remove = function (_id) {
        return this.http.delete(environment.apiUrl.admin + '/plans/' + _id);
    };
    PlanesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlanesService_Factory() { return new PlanesService(i0.ɵɵinject(i1.HttpClient)); }, token: PlanesService, providedIn: "root" });
    return PlanesService;
}());
export { PlanesService };
