<div class="content-wrapper">

    <div class="content-header row">
        <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">
                Cuentas Admin
            </h3>
            <div class="row breadcrumbs-top d-inline-block">
                <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/cuentas-administradores">
                                Cuentas Admin
                            </a>
                        </li>
                        <li class="breadcrumb-item active">
                            Formulario de cuentas de soporte
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-header-right col-md-4 col-12">
            <div class="btn-group float-md-right">
                <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="icon-settings mr-1"></i>
                    Opciones
                </button>
                <div class="dropdown-menu arrow">
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-life-ring mr-1"></i>
                        Support
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-cog mr-1"></i>
                        Settings
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body">
        <!-- Basic form layout section start -->
        <section>
            <div class="row ">
                <div class="col-md-6 col-md-offset-3" style="margin: 0 auto;">
                    

                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Estos son los datos basicos de la cuenta de soporte</h4>
                            <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                    <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                    <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                    <li><a data-action="close"><i class="ft-x"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content collapse show">
                            <div class="card-body">
            
                                <div class="card-text">
                                    <p>
                                        Diligencie el siguiente formulario para actualizar la informacion de la cuenta
                                    </p>
                                </div>
            
                                <form class="form" #formCuenta="ngForm" (ngSubmit)="save()" >

                                    <div class="form-group">
                                        <label for="identificacion">
                                            Identificación
                                        </label>
                                        <div class="position-relative has-icon-left">
                                            <input type="text" class="form-control input-lg" #fieldIdentificacion="ngModel" [(ngModel)]="formData.identificacion" name="identificacion" id="identificacion" placeholder="Ingrese aqui su identificación" required>
                                            <div class="form-control-position">
                                                <i class="fa fa-id-card" aria-hidden="true"></i>
                                            </div>
                                            <div class="help-block font-small-3"></div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldIdentificacion.invalid && (fieldIdentificacion.dirty || fieldIdentificacion.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldIdentificacion.errors.required">
                                                Campo Requerido
                                            </small>
                                        </p>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="nombres">
                                            Nombres
                                        </label>
                                        <div class="position-relative has-icon-left">
                                            <input type="text" class="form-control input-lg" #fieldNombres="ngModel" [(ngModel)]="formData.nombres" name="nombres" id="nombres" placeholder="Ingrese aqui su nombre" required>
                                            <div class="form-control-position">
                                                <i class="ft-user"></i>
                                            </div>
                                            <div class="help-block font-small-3"></div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldNombres.invalid && (fieldNombres.dirty || fieldNombres.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldNombres.errors.required">
                                                Campo Requerido
                                            </small>
                                        </p>
                                    </div>
            
                                    <div class="form-group">
                                        <label for="apellidos">
                                            Apellidos
                                        </label>
                                        <div class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control input-lg" #fieldApellidos="ngModel" [(ngModel)]="formData.apellidos" name="apellidos" id="apellidos" placeholder="Ingrese aqui su apellido" required>
                                            <div class="form-control-position">
                                                <i class="ft-user"></i>
                                            </div>
                                            <div class="help-block font-small-3"></div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldApellidos.invalid && (fieldApellidos.dirty || fieldApellidos.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldTelefono.errors.required">
                                                Campo Requerido
                                            </small>
                                        </p>
                                    </div>
            
                                    <div class="form-group">
                                        <label for="telefono">
                                            Teléfono
                                        </label>
                                        <div class="form-group position-relative has-icon-left">
                                            <input type="number" class="form-control input-lg" #fieldTelefono="ngModel"  [(ngModel)]="formData.telefono" name="telefono" id="telefono" placeholder="Ingrese aqui su telefono" required pattern="[0-9]{10}">
                                            <div class="form-control-position">
                                                <i class="ft-phone"></i>
                                            </div>
                                            <div class="help-block font-small-3"></div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldTelefono.invalid && (fieldTelefono.dirty || fieldTelefono.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldTelefono.errors.required">
                                                Campo Requerido
                                            </small>
                                            <small class="badge badge-default badge-danger" *ngIf="fieldTelefono.errors.pattern">
                                                Debe ser un número telefónico de 10 digitos, si es fijo agregarlo con indicativo
                                            </small>
                                        </p>
                                    </div>
            
                                    <div class="form-group">
                                        <label for="email">
                                            Correo
                                        </label>
                                        <div class=" position-relative has-icon-left">
                                            <input type="email" class="form-control input-lg" #fieldEmail="ngModel" [(ngModel)]="formData.email"  name="email" id="email" placeholder="Ingrese aqui su email" required email>
                                            <div class="form-control-position">
                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                            </div>
                                            <div class="help-block font-small-3"></div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldEmail.invalid && (fieldEmail.dirty || fieldEmail.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldEmail.errors.required">
                                                Campo Requerido
                                            </small>
                                            <small class="badge badge-default badge-danger" *ngIf="fieldEmail.errors.email">
                                                Debe ser un correo válido
                                            </small>
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label for="password">
                                            Contraseña
                                        </label>
                                        <div class=" position-relative has-icon-left">
                                            <input type="password" class="form-control input-lg" #fieldPassword="ngModel" [(ngModel)]="formData.password"  name="password" id="password" placeholder="*********" required>
                                            <div class="form-control-position">
                                                <i class="fa fa-lock" aria-hidden="true"></i>
                                            </div>
                                            <div class="help-block font-small-3"></div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldPassword.invalid && (fieldPassword.dirty || fieldPassword.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldPassword.errors.required">
                                                Campo Requerido
                                            </small>
                                            <small class="badge badge-default badge-danger" *ngIf="fieldPassword.errors.password">
                                                La contraseña debe contener al menos 8 caracteres
                                            </small>
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label for="repassword">
                                            Confirmar contraseña
                                        </label>
                                        <div class=" position-relative has-icon-left">
                                            <input type="password" class="form-control input-lg" #fieldRepassword="ngModel" [(ngModel)]="formData.repassword"  name="repassword" id="repassword" placeholder="*********" required>
                                            <div class="form-control-position">
                                                <i class="fa fa-lock" aria-hidden="true"></i>
                                            </div>
                                            <div class="help-block font-small-3"></div>
                                        </div>
                                        <p class="block-tag text-right" *ngIf="fieldRepassword.invalid && (fieldRepassword.dirty || fieldRepassword.touched) || ((formData.password != formData.repassword) && fieldRepassword.touched)">
                                            <small class="badge badge-default badge-danger" *ngIf="fieldRepassword.errors.required">
                                                Campo Requerido
                                            </small>
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <h2>Permisos</h2>
                                        <hr>
                                        <div class="col-md-12">
                                            <h3>Dashboard</h3>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="DASHBOARD_READ" id="DASHBOARD_READ" [(ngModel)]="formData.permisos.DASHBOARD_READ">
                                                <label for="DASHBOARD_READ">Crear</label>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <h3>Administradores</h3>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="ADMIN_ACCOUNTS_CREATE" id="ADMIN_ACCOUNTS_CREATE" [(ngModel)]="formData.permisos.ADMIN_ACCOUNTS_CREATE">
                                                <label for="ADMIN_ACCOUNTS_CREATE"> Crear</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="ADMIN_ACCOUNTS_READ" id="ADMIN_ACCOUNTS_READ" [(ngModel)]="formData.permisos.ADMIN_ACCOUNTS_READ">
                                                <label for="ADMIN_ACCOUNTS_READ"> Ver Detalles</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="ADMIN_ACCOUNTS_UPDATE" id="ADMIN_ACCOUNTS_UPDATE" [(ngModel)]="formData.permisos.ADMIN_ACCOUNTS_UPDATE">
                                                <label for="ADMIN_ACCOUNTS_UPDATE"> Actualizar</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="ADMIN_ACCOUNTS_DELETE" id="ADMIN_ACCOUNTS_DELETE" [(ngModel)]="formData.permisos.ADMIN_ACCOUNTS_DELETE">
                                                <label for="ADMIN_ACCOUNTS_DELETE"> Eliminar</label>
                                            </div>
                                            <hr>

                                            <h3>Cuentas</h3>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="ACCOUNTS_CREATE" id="ACCOUNTS_CREATE" [(ngModel)]="formData.permisos.ACCOUNTS_CREATE">
                                                <label for="ACCOUNTS_CREATE"> Crear</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="ACCOUNTS_READ" id="ACCOUNTS_READ" [(ngModel)]="formData.permisos.ACCOUNTS_READ">
                                                <label for="ACCOUNTS_READ"> Ver Detalles</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="ACCOUNTS_UPDATE" id="ACCOUNTS_UPDATE" [(ngModel)]="formData.permisos.ACCOUNTS_UPDATE">
                                                <label for="ACCOUNTS_UPDATE"> Actualizar</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="ACCOUNTS_DELETE" id="ACCOUNTS_DELETE" [(ngModel)]="formData.permisos.ACCOUNTS_DELETE">
                                                <label for="ACCOUNTS_DELETE"> Eliminar</label>
                                            </div>
                                            <hr>

                                            <h3>Empresas</h3>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="BUSINESS_CREATE" id="BUSINESS_CREATE" [(ngModel)]="formData.permisos.BUSINESS_CREATE">
                                                <label for="BUSINESS_CREATE"> Crear</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="BUSINESS_READ" id="BUSINESS_READ" [(ngModel)]="formData.permisos.BUSINESS_READ">
                                                <label for="BUSINESS_READ"> Ver Detalles</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="BUSINESS_UPDATE" id="BUSINESS_UPDATE" [(ngModel)]="formData.permisos.BUSINESS_UPDATE">
                                                <label for="BUSINESS_UPDATE"> Actualizar</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="BUSINESS_DELETE" id="BUSINESS_DELETE" [(ngModel)]="formData.permisos.BUSINESS_DELETE">
                                                <label for="BUSINESS_DELETE"> Eliminar</label>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <h3>Planes</h3>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="PLANS_CREATE" id="PLANS_CREATE" [(ngModel)]="formData.permisos.PLANS_CREATE">
                                                <label for="PLANS_CREATE"> Crear</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="PLANS_READ" id="PLANS_READ" [(ngModel)]="formData.permisos.PLANS_READ">
                                                <label for="PLANS_READ"> Ver Detalles</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="PLANS_UPDATE" id="PLANS_UPDATE" [(ngModel)]="formData.permisos.PLANS_UPDATE">
                                                <label for="PLANS_UPDATE"> Actualizar</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="PLANS_DELETE" id="PLANS_DELETE" [(ngModel)]="formData.permisos.PLANS_DELETE">
                                                <label for="PLANS_DELETE"> Eliminar</label>
                                            </div>
                                            <hr>

                                            <h3>Licencias</h3>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="LICENSES_CREATE" id="LICENSES_CREATE" [(ngModel)]="formData.permisos.LICENSES_CREATE">
                                                <label for="LICENSES_CREATE"> Crear</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="LICENSES_READ" id="LICENSES_READ" [(ngModel)]="formData.permisos.LICENSES_READ">
                                                <label for="LICENSES_READ"> Ver Detalles</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="LICENSES_UPDATE" id="LICENSES_UPDATE" [(ngModel)]="formData.permisos.LICENSES_UPDATE">
                                                <label for="LICENSES_UPDATE"> Actualizar</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="LICENSES_DELETE" id="LICENSES_DELETE" [(ngModel)]="formData.permisos.LICENSES_DELETE">
                                                <label for="LICENSES_DELETE"> Eliminar</label>
                                            </div>
                                            <hr>

                                            <h3>Módulos</h3>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="MODULES_CREATE" id="MODULES_CREATE" [(ngModel)]="formData.permisos.MODULES_CREATE">
                                                <label for="MODULES_CREATE"> Crear</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="MODULES_READ" id="MODULES_READ" [(ngModel)]="formData.permisos.MODULES_READ">
                                                <label for="MODULES_READ"> Ver Detalles</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="MODULES_UPDATE" id="MODULES_UPDATE" [(ngModel)]="formData.permisos.MODULES_UPDATE">
                                                <label for="MODULES_UPDATE"> Actualizar</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" name="MODULES_DELETE" id="MODULES_DELETE" [(ngModel)]="formData.permisos.MODULES_DELETE">
                                                <label for="MODULES_DELETE"> Eliminar</label>
                                            </div>
                                            <hr>
                                        </div>
                                    </div>
            
                                    <button type="submit" class="btn btn-danger btn-block btn-lg" [disabled]="!formCuenta.valid">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                        Guardar Cambios
                                    </button>
                                </form>
            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>