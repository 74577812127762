import { Component, OnInit } from '@angular/core';

declare var jQuery;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public title = 'admin';
    
    constructor(
    ) {

    }
    ngOnInit() {
        jQuery('[data-toggle="tooltip"]').tooltip()
    }
}

 