import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {

    transform(items: Array<any>, fecha_inicio: string, fecha_fin: string): Array<any> {

        if (!moment(fecha_inicio,"YYYY-MM-DD").isValid()) {
            return items;
        }

        if (!moment(fecha_fin, "YYYY-MM-DD").isValid()) {
            return items;
        }

        return items.filter((item) => {
            // se supone que todos los registos deben tener un campo created
            return moment(moment.unix(item.created)).isBetween(fecha_inicio + " 00:00:00", fecha_fin + " 23:59:59");
        });
    }

}
