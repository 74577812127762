<div class="content-wrapper">

    <div class="content-header row">
        <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">
                Modulos
            </h3>
            <div class="row breadcrumbs-top d-inline-block">
                <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/modulos">
                                Modulos
                            </a>
                        </li>
                        <li class="breadcrumb-item active">
                            Detalles de modulo
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-header-right col-md-4 col-12">
            <div class="btn-group float-md-right">
                <a class="btn btn-info" routerLink="funcionalidades"  >
                    <i class="icon-settings mr-1"></i>
                    Añadir Funcionalidades
                </a>
            </div>
        </div>
    </div>
    
    <div class="content-body">
        <!-- Basic form layout section start -->
        <section>
            <div class="row " >
                <div class="col-md-12 col-md-offset-3" style="margin: 0 auto;">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Información del Plan</h4>
                            <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                    <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                    <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                    <li><a data-action="close"><i class="ft-x"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content collapse show">
                            <div class="card-body">

                                <div class="card-text">
                                    <p>
                                        La siguiente es informacion del plan registrado
                                    </p>
                                </div>


                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th class="row" *ngIf="formData.nombre">
                                                Nombre:
                                            </th>
                                            <th>{{formData.nombre}}</th>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="row " >
                <div class="col-md-12 col-md-offset-3" style="margin: 0 auto;">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">funcionalidades del Plan</h4>
                            <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                    <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                    <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                    <li><a data-action="close"><i class="ft-x"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content collapse show">
                            <div class="card-body">

                                <div class="card-text">
                                    <p>
                                        La siguiente es informacion del plan registrado
                                    </p>
                                </div>


                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Hook</th>
                                            <th>Coleccion</th>
                                            <th>Maneja Documento</th>
                                            <th>Maneja Limite</th>
                                            <th>Tipo Limite</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="funcionalidades">
                                        <tr *ngFor="let funcionalidad of funcionalidades; let i = index">
                                            <td>
                                                {{funcionalidad.nombre}}
                                            </td>
                                            <td>
                                                {{funcionalidad.hook}}
                                            </td>
                                            <td>
                                                {{funcionalidad.coleccion}}
                                            </td>
                                            <td>
                                                <div *ngIf="funcionalidad.maneja_documento">Si</div>
                                                <div *ngIf="!funcionalidad.maneja_documento">No</div>
                                            </td>
                                            <td>
                                                <div *ngIf="funcionalidad.maneja_limite">Si</div>
                                                <div *ngIf="!funcionalidad.maneja_limite">No</div>
                                            </td>
                                            <td>
                                                <div *ngIf="funcionalidad.tipo_limite == 'PLAN'">Empresa</div>
                                                <div *ngIf="funcionalidad.tipo_limite == 'ROL'">Usuario</div>
                                            </td>
                                            <td>
                                                <a routerLink="funcionalidades/{{funcionalidad._id}}" class="btn btn-primary"><i class="fa fa-pencil"></i></a>
                                                <button class="btn btn-danger" (click)="delete(funcionalidad._id,i)"><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- // Basic form layout section end -->
    </div>
</div>