import { Directive, Input, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

declare var jQuery;

@Directive({
  selector: '[appSelect2]'
})
export class Select2Directive {

    @Input()
    appSelect2: string;

    @Input('place') 
    placeholder:string;

    constructor(
        private element: ElementRef,
        private ngControl: NgControl
        ) {
        setTimeout(() => {
            this.loadPlugin();
        }, 800);
    }

    private loadPlugin() {

        const that = this;

        jQuery(this.element.nativeElement).select2({
            placeholder: this.placeholder,
            width: '100%',
        });

        jQuery(that.element.nativeElement).on('select2:select', function (e) { 
            const ctrl = that.ngControl.control;
            ctrl.setValue(that.element.nativeElement.value);
        });
    }

}
