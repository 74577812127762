
export class ConexionBd {

	public _id: string;
	public port: string;
	public host: string;
	public username: string;
	public password: string;
	public database: string;
    public empresa_id: string;
    
	constructor(){
		this._id = null;
		this.port = null;
		this.host = null;
		this.username = null;
		this.password = null;
		this.database = null;
		this.empresa_id = null;
	}
}
