<div class="content-wrapper">

        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">
                    Planes
                </h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a routerLink="/dashboard">
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item">
                                <a routerLink="/planes">
                                    Planes
                                </a>
                            </li>
                            <li class="breadcrumb-item active">
                                Formulario Planes
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-4 col-12">
                <div class="btn-group float-md-right">
                    <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="icon-settings mr-1"></i>
                        Opciones
                    </button>
                    <div class="dropdown-menu arrow">
                        <a class="dropdown-item" routerLink="/planes/add">
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            Nuevo Plan
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="fa fa-cart-plus mr-1"></i>
                            Cart
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="fa fa-life-ring mr-1"></i>
                            Support
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">
                            <i class="fa fa-cog mr-1"></i>
                            Settings
                        </a>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section>
                <div class="row " >
                    <div class="col-md-6 col-md-offset-3" style="margin: 0 auto;">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Información del Plan</h4>
                                <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                                <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                        <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                        <li><a data-action="close"><i class="ft-x"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body">
    
                                    <div class="card-text">
                                        <p>
                                            La siguiente es informacion del plan registrado
                                        </p>
                                    </div>
    

                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    Nombre
                                                </th>
                                                <td>
                                                    {{plan.nombre}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Precio
                                                </th>
                                                <td>
                                                    {{plan.precio | currency:"$"}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Dias de Prueba
                                                </th>
                                                <td>
                                                    {{plan.dias_prueba}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- // Basic form layout section end -->
        </div>
    
    </div>