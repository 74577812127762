export class FuncionalidadModulo {
    public _id: string;
    public nombre: string;
    public hook: string;
    public modulo_id: string;
    public maneja_documento: boolean;
    public maneja_limite: boolean;
    public tipo_limite: string;
    public coleccion: string;
    public created: number
    public updated: number;

    constructor() {
        this._id = null;
        this.nombre = null;
        this.hook = null;
        this.modulo_id = null;
        this.maneja_documento = false;
        this.maneja_limite = false;
        this.tipo_limite = null;
        this.coleccion = null;
        this.created = null;
        this.updated = null;
    }
}
