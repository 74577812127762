export class Licencia {

    public _id: string;
    public consecutivo: number;
    public fecha_inicio: number | string;
    public fecha_vencimiento: number | string;
    public cuenta_id: string;
    public empresa_id: string;
    public plan_id: string;
    public estado: string;
    public tipo: string;
    public created: number;
    public updated: number;

    constructor() {
        this._id = null;
        this.consecutivo = null;
        this.fecha_inicio = null;
        this.fecha_vencimiento = null;
        this.cuenta_id = null;
        this.empresa_id = null;
        this.plan_id = null;
        this.estado = null;
        this.tipo = null;
        this.created = null;
        this.updated = null;
    }

}
