<div class="row">
    <div class="col-sm-12 col-md-6 col-md-offset-3" style="margin: 0 auto;">

        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Cambiar Contraseña de Cuenta</h4>
                <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                <div class="heading-elements">
                    <ul class="list-inline mb-0">
                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                        <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                        <li><a data-action="close"><i class="ft-x"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="card-content collapse show">
                <div class="card-body">

                    <div class="card-text">
                        <p>
                            Diligencie el siguiente formulario para actualizar la contraseña
                        </p>
                    </div>


                    <form class="form" (ngSubmit)="save()">

                        <div class="form-group">
                            <label for="new_pass">
                                Contraseña Nueva
                            </label>
                            <div class="position-relative has-icon-left">
                                <input type="password" class="form-control input-lg" [(ngModel)]="formData.new_pass" name="new_pass" id="new_pass" placeholder="Ingrese aqui la nueva contraseña" required>
                                <div class="form-control-position">
                                    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                                </div>
                                <div class="help-block font-small-3"></div>
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label for="renew_pass">
                                Vuelva e ingrese contraseña Nueva
                            </label>
                            <div class="position-relative has-icon-left">
                                <input type="password" class="form-control input-lg" [(ngModel)]="formData.renew_pass" name="renew_pass" id="renew_pass" placeholder="Repita aqui la nueva contraseña" required>
                                <div class="form-control-position">
                                    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                                </div>
                                <div class="help-block font-small-3"></div>
                            </div>
                        </div>


                        <button type="submit" class="btn btn-danger btn-block btn-lg">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            Actualizar Contraseña
                        </button>
                    </form>


                </div>
            </div>
        </div>

    </div>
</div>