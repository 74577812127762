<div class="content-wrapper">

    <div class="content-header row">
        <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">
                Empresas
            </h3>
            <div class="row breadcrumbs-top d-inline-block">
                <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/empresas">
                                Empresas
                            </a>
                        </li>
                        <li class="breadcrumb-item active">
                            Formulario para el registro de Empresa
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-header-right col-md-4 col-12">
            <div class="btn-group float-md-right">
                <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="icon-settings mr-1"></i>
                    Opciones
                </button>
                <div class="dropdown-menu arrow">
                    <a class="dropdown-item" routerLink="/planes/add">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        Nuevo Plan
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-cart-plus mr-1"></i>
                        Cart
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-life-ring mr-1"></i>
                        Support
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                        <i class="fa fa-cog mr-1"></i>
                        Settings
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body">
        <!-- Basic form layout section start -->
        <section>
            <div class="row ">
                <div class="col-md-6 col-md-offset-3" style="margin: 0 auto;">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Información de la Empresa</h4>
                            <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                    <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                    <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                    <li><a data-action="close"><i class="ft-x"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content collapse show">
                            <div class="card-body">

                                <div class="card-text">
                                    <p *ngIf="formData._id == null">
                                        Diligencie el siguiente formulario para registrar la empresa
                                    </p>
                                    <p *ngIf="formData._id != null">
                                        Diligencie el siguiente formulario para actualizar la información de la empresa
                                    </p>
                                </div>

                                <form class="form" (ngSubmit)="save()" #FormEmpresas="ngForm">
                                    <div class="form-body">

                                        <div class="form-group" *ngIf="formData._id == null || (formData._id != null && formData.cuenta_id != null)">
                                            <label for="licencia_cuenta">
                                                Cuenta
                                            </label>
                                            <select  name="licencia_cuenta" id="licencia_cuenta" required #FieldCuenta="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.cuenta_id">
                                                <ng-container *ngFor="let cuenta of cuentas">
                                                    <option value="{{cuenta._id}}">
                                                        {{cuenta.nombres}} - {{cuenta.apellidos}}
                                                    </option>
                                                </ng-container>
                                            </select>
                                            <p class="block-tag text-right" *ngIf="FieldCuenta.invalid && (FieldCuenta.dirty || FieldCuenta.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="FieldCuenta.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>

                                        <div class="form-group" *ngIf="formData._id == null || (formData._id != null && formData.cuenta_id != null)">
                                            <label for="tipo_identificacion">
                                                Tipo de Identificación
                                            </label>
                                            <select name="tipo_identificacion" id="tipo_identificacion" required #fieldTI="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.tipo_identificacion">
                                                <option value="" selected>Seleccionar</option>
                                                <option value="CC">Cédula de Ciudadania</option>
                                                <option value="TI">Tarjeta de Identidad</option>
                                                <option value="PP">Pasaporte</option>
                                                <option value="NIT">NIT</option>
                                                <option value="NITE">NIT Extranjero</option>
                                                <option value="RC">Registro Cívil</option>
                                                <option value="TE">Tarjeta Extranjería</option>
                                                <option value="CE">Cédula Extranjería</option>
                                                <option value="DIE">Documento de Identificación Extranjero</option>
                                                <option value="PEP">Permiso Especial de Permanencia</option>
                                                <option value="NUIP">Número Único de Identificación Personal</option>
                                            </select>
                                            <p class="block-tag text-right" *ngIf="fieldTI.invalid && (fieldTI.dirty || fieldTI.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="fieldTI.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>

                                        <div class="form-group">
                                            <label for="nit">
                                                Numero de Identificación ({{formData.tipo_identificacion}})
                                            </label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" id="nit" class="form-control" #FieldNit="ngModel" required [(ngModel)]="formData.nit" placeholder="Ingrese aqui el nit de la empresa"  name="nit">
                                                <div class="form-control-position">
                                                    <i class="fa fa-building" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="block-tag text-right" *ngIf="FieldNit.invalid && (FieldNit.dirty || FieldNit.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="FieldNit.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>

                                        <div class="form-group" *ngIf="formData._id == null || (formData._id != null && formData.cuenta_id != null)">
                                            <label for="tipo_organizacion">
                                                Tipo de Organización
                                            </label>
                                            <select name="tipo_organizacion" id="tipo_organizacion" required #fieldTO="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.tipo_organizacion">
                                                <option value="PN">Persona Natural</option>
                                                <option value="PJ">Persona Juridica</option>
                                            </select>
                                            <p class="block-tag text-right" *ngIf="fieldTO.invalid && (fieldTO.dirty || fieldTO.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="fieldTO.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>

                                        <div class="form-group" *ngIf="formData.tipo_organizacion== 'PJ'">
                                            <label for="nit">
                                                Dígito de Verificación
                                            </label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" id="nit" class="form-control" #FieldDV="ngModel" required [(ngModel)]="formData.dv" placeholder="Ingrese aqui el dígito de verificación de la empresa"  name="dv">
                                                <div class="form-control-position">
                                                    <i class="fa fa-hashtag" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="block-tag text-right" *ngIf="FieldDV.invalid && (FieldDV.dirty || FieldDV.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="FieldDV.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>

                                        <div class="form-group">
                                            <label for="razon_social">
                                                Razon Social
                                            </label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" id="razon_social" #FieldRzon="ngModel" required class="form-control" [(ngModel)]="formData.razon_social" placeholder="Ingrese aqui la razon social"  name="razon_social">
                                                <div class="form-control-position">
                                                    <i class="fa fa-building-o" aria-hidden="true"></i>
                                                </div>
                                                <p class="block-tag text-right" *ngIf="FieldRzon.invalid && (FieldRzon.dirty || FieldRzon.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="FieldRzon.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>
                                        </div>

                                        
                                        <div class="form-group">
                                            <label for="nombre_comercial">
                                                Nombre Comercial
                                            </label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" id="nombre_comercial" #FieldNombreComercial="ngModel" required class="form-control" [(ngModel)]="formData.nombre_comercial" placeholder="Ingrese aqui el nombre de comercial"  name="nombre_comercial">
                                                <div class="form-control-position">
                                                    <i class="fa fa-handshake-o" aria-hidden="true"></i>
                                                </div>
                                                <p class="block-tag text-right" *ngIf="FieldNombreComercial.invalid && (FieldNombreComercial.dirty || FieldNombreComercial.touched)">
                                                    <small class="badge badge-default badge-danger" *ngIf="FieldNombreComercial.errors.required">
                                                        Campo Requerido
                                                    </small>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="contacto">
                                                Email de la empresa registrado en la DIAN
                                            </label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" id="contacto" #FieldEmail="ngModel" email required class="form-control" [(ngModel)]="formData.email" placeholder="Ingrese aqui el email de contacto"  name="email">
                                                <div class="form-control-position">
                                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="block-tag text-right" *ngIf="FieldEmail.invalid && (FieldEmail.dirty || FieldEmail.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="FieldEmail.errors.required">
                                                    Campo Requerido
                                                </small>
                                                <small class="badge badge-default badge-danger" *ngIf="FieldEmail.errors.email">
                                                    Email con formato incorrecto
                                                </small>
                                            </p>
                                        </div>

                                        <div class="form-group">
                                            <label for="contacto">
                                                Nombre de Contacto
                                            </label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" id="contacto" #FieldContacto="ngModel" required class="form-control" [(ngModel)]="formData.contacto" placeholder="Ingrese nombre del contacto"  name="contacto">
                                                <div class="form-control-position">
                                                    <i class="fa fa-address-card-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="block-tag text-right" *ngIf="FieldContacto.invalid && (FieldContacto.dirty || FieldContacto.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="FieldContacto.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>


                                        <div class="form-group" >
                                            <label for="plan">
                                                Plan
                                            </label>
                                            <select name="plan" id="plan" required #fieldPlan="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.plan_id">
                                                <option value="{{plan._id}}" *ngFor="let plan of planes">
                                                    {{plan.nombre}} - {{plan.precio | currency: $}}
                                                </option>
                                            </select>
                                            <p class="block-tag text-right" *ngIf="fieldPlan.invalid && (fieldPlan.dirty || fieldPlan.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="fieldPlan.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>


                                        <div class="form-group" >
                                            <label for="pais">
                                                Pais
                                            </label>
                                            <select name="pais" id="pais" [appSelect2] required #fieldPais="ngModel" class="form-control"  [(ngModel)]="formData.direccion.pais.code" (ngModelChange)="changePais()" >
                                                <option [ngValue]="null" selected="selected">Seleccione</option>
                                                <option *ngFor="let pais of regiones.paises" [value]="pais.code" >{{pais.value}}</option>
                                            </select>
                                            <p class="block-tag text-right" *ngIf="fieldPais.invalid && (fieldPais.dirty || fieldPais.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="fieldPais.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>

                                        
                                        <div class="form-group" *ngIf="formData.direccion.pais.code == 'CO' " >
                                            <label for="departamento">
                                                Departamento
                                            </label>
                                            <select name="departamento" id="departamento" required #fieldDepartamento="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.direccion.departamento.code" (ngModelChange)="changeDepartamento()">
                                                <option [ngValue]="null" selected="selected">Seleccione</option>
                                                <option *ngFor="let departamento of regiones.departamentos" [value]="departamento.code">
                                                    {{departamento.value}}
                                                </option>
                                            </select>
                                            <p class="block-tag text-right" *ngIf="fieldDepartamento.invalid && (fieldDepartamento.dirty || fieldDepartamento.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="fieldDepartamento.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>

                                        

                                        <div class="form-group" *ngIf="formData.direccion.pais.code == 'CO' ">
                                            <label for="municipio">
                                                Municipio
                                            </label>
                                            <select name="municipio" id="municipio" required #fieldMunicipio="ngModel" class="form-control" [appSelect2] [(ngModel)]="formData.direccion.municipio.code" (ngModelChange)="changeMunicipio()">
                                                <option [ngValue]="null" selected="selected">Seleccione</option>
                                                <ng-container *ngFor="let municipio of regiones.municipios" >
                                                    <option [value]="municipio.code" *ngIf="municipio.departmentCode == formData.direccion.departamento.code" >
                                                        {{municipio.value}}
                                                    </option>
                                                </ng-container>
                                            </select>
                                            <p class="block-tag text-right" *ngIf="fieldMunicipio.invalid && (fieldMunicipio.dirty || fieldMunicipio.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="fieldMunicipio.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>


                                        <div class="form-group">
                                            <label for="direccion">
                                                Direccion
                                            </label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" name="direccion" id="direccion" #FieldDireccion="ngModel" required class="form-control" [(ngModel)]="formData.direccion.calle" placeholder="Ingrese aqui la direccion" />
                                                <div class="form-control-position">
                                                    <i class="fa fa-address-card-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="block-tag text-right" *ngIf="FieldDireccion.invalid && (FieldDireccion.dirty || FieldDireccion.touched)">
                                                <small class="badge badge-default badge-danger" *ngIf="FieldDireccion.errors.required">
                                                    Campo Requerido
                                                </small>
                                            </p>
                                        </div>


                                    </div>
                                    <div class="form-actions right">
                                        <a class="btn btn-light mr-1" routerLink="/empresas">
                                            <i class="ft-x"></i> Cancelar
                                        </a>
                                        <button [disabled]="!FormEmpresas.valid" type="submit" class="btn btn-success">
                                            <i class="fa fa-check-square-o"></i> Guardar
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- // Basic form layout section end -->
    </div>

</div>