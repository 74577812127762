export class Modulo {
    public _id: String;
    public nombre: String;
    public created: number | string;
    public updated: number | string;

    constructor() {
        this._id = null;
        this.nombre = null;
        this.created = null;
        this.updated = null;
    }
}