import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gridFilter'
})
export class GridFilterPipe implements PipeTransform {

    transform(items: Array<any>, search: string = null): Array<any> {

        // validamos que haya items y que tambien algo para buscar
        if (!Array.isArray(items) || search === null) {
            return items;
        }

        // creamos una validacion que contenga algo la busqueda aparte para que no choque en la validacion anterior en caso de que sea null
        if (search.trim() === "") {
            return items;
        }

        // filtramos para devolver
        return items.filter(item => {
            return this.filter(search, item);
        });
    }

    /**
     * Permite buscar de manera recursiva 
     */
    private filter(search: string, item) {

        // validamos si lo que se esta trabajando es un arreglo de objetos primitivos
        if (this.isPrimitive(item)) {
            return new RegExp(search, 'gi').test(item);
        }

        // obtenemos las llaves de cada item
        const itemsKeys = Object.keys(item);

        // ejecutamos el metodo de reduce para encontrar en todos los campos alguna coincidencia con lo que se esta buscando
        // enviamos false en el segundo argumento para indicar desde  un inicio que no se ha encontrado nada
        return itemsKeys.reduce((previosValue, keyName) => {

            // si el valor anterior es true, 
            // inmediatamente devolvemos true por que ya no queremos que siga buscando
            if(previosValue){
                return true;
            }

            // si el valor de la propiedad es primitivo
            // verificamos si tienen alli lo que estamos buscando
            if (this.isPrimitive(item[keyName])) {
                return new RegExp(search, 'gi').test(item[keyName]);
            }

            // como no es primitivo, entonces ejecutamos otra busqueda internamente alli
            return this.filter(search, item[keyName]);
        }, false)
    }


    /**
     * Permite verificar si una variable es primitiva
     */
    private isPrimitive(customVar) {
        return (customVar !== Object(customVar));
    };

}
