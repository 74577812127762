/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./menu.component";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
export { RenderType_MenuComponent as RenderType_MenuComponent };
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 43, "div", [["class", "main-menu-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 42, "ul", [["class", "navigation navigation-main"], ["data-menu", "menu-navigation"], ["id", "main-menu-navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "li", [["class", " nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "a", [["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Dashboard "])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "li", [["class", " nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "a", [["routerLink", "/cuentas-administradores"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-globe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Administradores "])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "li", [["class", " nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "a", [["routerLink", "/cuentas"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fa fa-rocket"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cuentas "])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "li", [["class", " nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 4, "a", [["routerLink", "/empresas"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "ft-thumbs-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Empresas "])), (_l()(), i1.ɵeld(26, 0, null, null, 5, "li", [["class", " nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 4, "a", [["routerLink", "/planes"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 0, "i", [["class", "fa fa-credit-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Planes "])), (_l()(), i1.ɵeld(32, 0, null, null, 5, "li", [["class", " nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 4, "a", [["routerLink", "/licencias"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 34).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(34, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(35, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-copyright"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Licencias "])), (_l()(), i1.ɵeld(38, 0, null, null, 5, "li", [["class", " nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 4, "a", [["routerLink", "/modulos"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 40).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(41, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-puzzle-piece"]], null, null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Modulos "]))], function (_ck, _v) { var currVal_2 = "/dashboard"; _ck(_v, 4, 0, currVal_2); var currVal_5 = "/cuentas-administradores"; _ck(_v, 10, 0, currVal_5); var currVal_8 = "/cuentas"; _ck(_v, 16, 0, currVal_8); var currVal_11 = "/empresas"; _ck(_v, 22, 0, currVal_11); var currVal_14 = "/planes"; _ck(_v, 28, 0, currVal_14); var currVal_17 = "/licencias"; _ck(_v, 34, 0, currVal_17); var currVal_20 = "/modulos"; _ck(_v, 40, 0, currVal_20); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 10).target; var currVal_4 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 16).target; var currVal_7 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 22).target; var currVal_10 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 28).target; var currVal_13 = i1.ɵnov(_v, 28).href; _ck(_v, 27, 0, currVal_12, currVal_13); var currVal_15 = i1.ɵnov(_v, 34).target; var currVal_16 = i1.ɵnov(_v, 34).href; _ck(_v, 33, 0, currVal_15, currVal_16); var currVal_18 = i1.ɵnov(_v, 40).target; var currVal_19 = i1.ɵnov(_v, 40).href; _ck(_v, 39, 0, currVal_18, currVal_19); }); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu", [], null, null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i4.MenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuComponentNgFactory = i1.ɵccf("app-menu", i4.MenuComponent, View_MenuComponent_Host_0, {}, {}, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };
