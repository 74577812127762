import { Directive, ElementRef, ViewContainerRef, TemplateRef, Input, EmbeddedViewRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NgIfContext } from '@angular/common';

@Directive({
    selector: '[appRequireAuth]'
})
export class RequireAuthDirective {

    @Input()
    appRequireAuth: string;

    private context: NgIfContext = new NgIfContext();
    private thenTemplateRef: TemplateRef<NgIfContext> | null = null;
    private thenViewRef: EmbeddedViewRef<NgIfContext> | null = null;

    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<NgIfContext>,
        private element: ElementRef,
        private authService: AuthService
    ) {
        this.thenTemplateRef = templateRef;

        // escuchamos para cuando el sistema detecte un inicio de sesion
        this.authService.loginEvent.subscribe((user) => {
            this.setCondition(true);
        });

        // escuchamos para cuando el sistema detecte un cierre de sesion
        this.authService.logoutEvent.subscribe(user => {
            this.setCondition(false);
        });

        // inicialmente verificamos si el usuario esta registrado
        this.setCondition(this.authService.userLogged !== null);
    }

    /**
     * Permite establecer una condicion y posteriormente renderizar o no de acuerdo a la condición
     * @param condition 
     */
    private setCondition(condition: boolean) {
        this.context.$implicit = this.context.ngIf = condition;
        this.updateView();
    }


    /**
     * Permite actualizar la vista renderizando o no de acuerdo a la condicion inicial como if
     */
    private updateView() {

        if (!this.context.ngIf) {
            this.viewContainer.clear();
        }

        if (this.context.$implicit) {
            if (!this.thenViewRef) {
                if (this.thenTemplateRef) {
                    this.thenViewRef = this.viewContainer.createEmbeddedView(this.thenTemplateRef, this.context);
                }
            }
        } else {
            this.thenViewRef = null;
        }
    }

}
