import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ModulosService = /** @class */ (function () {
    function ModulosService(http) {
        this.http = http;
    }
    /**
     * Permite obtener todos los registros de modulos que hay en la base de datos
     */
    ModulosService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl.admin + "/modules");
    };
    /**
     * Permite obtener un registro relacionado con el id
     * @param id
     */
    ModulosService.prototype.getById = function (id) {
        return this.http.get(environment.apiUrl.admin + "/modules/" + id);
    };
    /**
     * Permite guardar la entidad de un modulo
     * @param formData
     */
    ModulosService.prototype.save = function (formData) {
        return (formData._id === null) ? this.add(formData) : this.edit(formData);
    };
    /**
     * Permite agregar un modulo
     * @param formData
     */
    ModulosService.prototype.add = function (formData) {
        delete formData._id;
        return this.http.post(environment.apiUrl.admin + "/modules", formData);
    };
    /**
     * Permiter guardar los cambios de un modulo
     * @param formData
     */
    ModulosService.prototype.edit = function (formData) {
        return this.http.put(environment.apiUrl.admin + "/modules/" + formData._id, formData);
    };
    /**
     * Permite eliminar un modulo
     * @param _id
     */
    ModulosService.prototype.delete = function (_id) {
        return this.http.delete(environment.apiUrl.admin + "/modules/" + _id);
    };
    ModulosService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModulosService_Factory() { return new ModulosService(i0.ɵɵinject(i1.HttpClient)); }, token: ModulosService, providedIn: "root" });
    return ModulosService;
}());
export { ModulosService };
