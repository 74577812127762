import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Usuario } from 'src/app/entities/usuario';

import Swal from 'sweetalert2';

declare var jQuery;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    formData: Usuario;

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
        this.formData = new Usuario();
    }

    ngOnInit() {
        this.authService.closeSession();
    }
    
    async login() {

        jQuery.fancybox.showLoading();

        // consultamos si existe algun registro de usuario con los parametros recibidos
        const userData: Usuario = await this.authService.login(this.formData);

        // verificamos si la consulta devolvio algun registro
        if (userData == null) {
            Swal({
                type: 'error',
                title: 'Oops...',
                text: 'Usuario o contraseña incorrecta!'
            });
        } else {
            this.router.navigate(['/cuentas']);
        }

        jQuery.fancybox.hideLoading();
    }


}
