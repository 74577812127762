export class ImplementacionEmpresa {
	 public _id : string;
    public empresa_id: string;
    public empresaConfiguracion;
    public nominaConfiguracion;
    public usuariosConfiguracion;
    public portafolioConfiguracion;
    public facturacionConfiguracion;
    public gastosConfiguracion;
    public tesoreriaConfiguracion;
    public facturacionElectronicaConfiguracion;
    public portafolioAdministracion;
    public tercerosAdministracion;
    public nominaAdministracion;
    public tesoreriaAdministracion;
    public comprasAdministracion;
    public gastosAdministracion;
    public facturacionAdministracion;
    public facturacionElectronicaAdministracion;

    constructor() {
        this.empresa_id = null;
        this._id = null;
        this.empresaConfiguracion = {
            ajustes: false,
            documentos: false,
            formasPago: false
        }
        this.nominaConfiguracion = {
            empleados: false,
            comisiones: false,
            tipos_nomina: false,
            cargos: false
        }
        this.usuariosConfiguracion = {
            crear_usuarios: false,
            roles: false,
            asignarPermisos: false
        }
        this.portafolioConfiguracion = {
            categorias: false,
            atributosProductos: false,
            tiposUnidades: false,
            impuestos: false
        }
        this.facturacionConfiguracion = {
            cajaPos: false,
            mediosPago: false
        }
        this.gastosConfiguracion = {
            categorias: false
        }
        this.tesoreriaConfiguracion = {
            categorias: false,
            atributosProducto: false,
            tiposUnidades: false,
            impuestos: false
        }

        this.facturacionElectronicaConfiguracion = {
            resolucion: false,
            parametrizacion: false,
            documento: false,
            emparejamientoImpuestos: false,
            setPruebas: false,
            validacionDirecta: false,
        }

        this.portafolioAdministracion = {
            servicios: false,
            productos: false,
            inventario: false,
            movimientosInventario: false
        }

        this.tercerosAdministracion = {
            clientes: false,
            proveedores: false
        }
        this.nominaAdministracion = {
            nomina: false,
            movimientosNomina: false,
            prestamos: false
        }
        this.tesoreriaAdministracion = {
            cuentasBancarias: false,
            conceptosConciliacion: false,
            movimientosConciliacion: false
        }

        this.comprasAdministracion = {
            registrarCompra: false,
            pagarCompra: false,
            editarCompra: false
        }
        this.gastosAdministracion = {
            registrarGastos: false,
            pagarGastos: false,
            editarGastos: false
        }

        this.facturacionAdministracion = {
            realizarFacturas: false,
            editarFacturas: false,
            recaudoFacturas: false,
            cierreCaja: false
        }
        
        this.facturacionElectronicaAdministracion = {
            realizarFacturasElectronicas: false,
            editarFacturasElectronicas: false,
            recaudoFacturasElectronicas: false,
            emitirFacturasElectronicas: false
        }
    }
}
