import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FuncionalidadModulo } from '../entities/funcionalidad-modulo';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class FuncionalidadesModuloService {

    constructor(private http: HttpClient) { }

    /**
     * Permite obtener todas las funcionalidades agrupadads por coleccione y las colecciones agrupadas por modulo
     */
    public getAllByScheme() {
        return this.http.get(environment.apiUrl.admin + "/functionalities-scheme");
    }

    public getById(id: String) {
        return this.http.get(`${environment.apiUrl.admin}/modules-functionalities/${id}`);
    }

    public getAll() {
        return this.http.get(environment.apiUrl.admin + "/modules-functionalities");
    }

    public getByModule(module_id: String) {
        return this.http.get(`${environment.apiUrl.admin}/modules/${module_id}/modules-functionalities `)
    }

    public save(form_data: FuncionalidadModulo) {
        console.log(form_data._id === null);
        if (form_data._id === null) {
            return this.add(form_data);
        }
        return this.edit(form_data);
    }

    private add(form_data: FuncionalidadModulo) {
        delete form_data._id;
        return this.http.post(`${environment.apiUrl.admin}/modules-functionalities`, form_data);
    }

    private edit(form_data: FuncionalidadModulo) {
        return this.http.put(`${environment.apiUrl.admin}/modules-functionalities/${form_data._id}`, form_data);
    }

    public delete(_id: String) {
        return this.http.delete(`${environment.apiUrl.admin}/modules-functionalities/${_id}`);
    }
}
