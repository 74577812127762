import { Component, OnInit } from '@angular/core';
import { FuncionalidadModulo } from '../../entities/funcionalidad-modulo';
import { FuncionalidadesModuloService } from '../../services/funcionalidades-modulo.service';
import { ActivatedRoute, Router} from '@angular/router';
import swal from 'sweetalert2';
@Component({
  selector: 'app-form-funcionalidad-modulo',
  templateUrl: './form-funcionalidad-modulo.component.html',
  styleUrls: ['./form-funcionalidad-modulo.component.scss']
})
export class FormFuncionalidadModuloComponent implements OnInit {
	public formData:FuncionalidadModulo;
	constructor(public funcionalidadesModuloService:FuncionalidadesModuloService, public router:ActivatedRoute, public route:Router) {
		this.formData = new FuncionalidadModulo();
		this.router.params.subscribe(params=>{
			console.log(params);
			if (typeof params.id!== "undefined") {
				this.load(params.id);
			}else if (typeof params.modulo !== "undefined") {
				this.formData.modulo_id = params.modulo;
				console.log(this.formData);
			}
		});
	}

	ngOnInit() {
	}
	public load(id){
		this.funcionalidadesModuloService.getById(id).subscribe((funcionalidad:FuncionalidadModulo)=>{
			this.formData = funcionalidad;
		});
	}

	public save(){
		this.funcionalidadesModuloService.save(this.formData).subscribe(succ=>{
			swal({
				title:'Se guardó correctamente',
				type:'success',
				showConfirmButton:false,
				timer:1200
			});
			this.route.navigate(["/modulos/"+this.formData.modulo_id +"/detalles"]);
		});
	}
}
