import { Component, OnInit } from '@angular/core';
import { Plan } from 'src/app/entities/plan';
import { PlanesService } from 'src/app/services/planes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FuncionalidadesModuloService } from 'src/app/services/funcionalidades-modulo.service';
import { FuncionalidadPlan } from 'src/app/entities/funcionalidad-plan';
import { FuncionalidadesPlanService } from 'src/app/services/funcionalidades-plan.service';
import { FuncionalidadEsquema } from 'src/app/entities/funcionalidad-esquema';

declare var jQuery;

@Component({
    selector: 'app-form-planes',
    templateUrl: './form-planes.component.html',
    styleUrls: ['./form-planes.component.scss']
})
export class FormPlanesComponent implements OnInit {

    formPlan: Plan;
    funcionalidadesPlan: Array<FuncionalidadPlan>;
    esquemaFuncionalidades: Array<FuncionalidadEsquema>;

    constructor(
        private planesService: PlanesService,
        private funcionalidadesModuloService: FuncionalidadesModuloService,
        private funcionalidadesPlanService: FuncionalidadesPlanService,
        private router: Router,
        private currentRoute: ActivatedRoute
    ) {

        this.formPlan = new Plan();
        this.funcionalidadesPlan = [];

        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                // cargamos la informacion del plan
                this.load(params.id);

                // cargamos las funcionalidades que tiene asignando el plan
                this.loadFuncionalidadesPlan(params.id);
            }
        });
    }

    ngOnInit() {
        this.loadEsquemaFuncionalidades();
    }

    /**
     * Permite cargar la informacion del plan, para que se muestre en el formulario y se pueda editar
     * @param _id 
     */
    private load(_id: string) {
        jQuery.fancybox.showLoading();
        this.planesService.get(_id)
            .subscribe((response: Plan) => {
                this.formPlan = response;
                jQuery.fancybox.hideLoading();
            })
    }

    /**
     * Permite cargar las funcionalidades que se han seleccionando en el plan
     * @param plan_id 
     */
    private loadFuncionalidadesPlan(plan_id: string) {
        this.funcionalidadesPlanService.getByPlanId(plan_id)
            .subscribe((response: Array<FuncionalidadPlan>) => {
                this.funcionalidadesPlan = response;
            });
    }

    /**
     * Permite cargar todas las funcionalidades de cada modulo agrupados entre coleccion y modulo
     */
    private loadEsquemaFuncionalidades() {
        this.funcionalidadesModuloService.getAllByScheme()
            .subscribe((response: Array<FuncionalidadEsquema>) => {
                this.esquemaFuncionalidades = response;
            });
    }

    /**
     * Permite verificar si una funcion ya esta agregada
     * @param funcion_id 
     */
    public checkExistFuncionPlan(funcion_id: string): boolean {

        // recorremos las funcionalidades agregadas previamente
        for (const funcion of this.funcionalidadesPlan) {

            // verificamos si es la funcion que estamos buscando
            if (funcion.funcionalidad_modulo_id == funcion_id) {
                return true;
            }
        }

        return false;
    }

    /**
     * Permite buscar la funcionalidad y asi eliminarla si existe,
     * si no existe es por que la funcionalidad se esta agregando 
     */
    public toggleFunctionPlan(funcion_id: string) {

        let funcionEncontrada = false;

        // recorremos las funcionalidades agregadas previamente
        for (const index in this.funcionalidadesPlan) {

            // obtenemos la funcion de la actual iteración
            const funcion = this.funcionalidadesPlan[index];

            // verificamos si es la funcion que estamos buscando
            if (funcion.funcionalidad_modulo_id == funcion_id) {
                funcionEncontrada = true;

                // eliminamos la funcion
                this.funcionalidadesPlan.splice(Number(index), 1);
                break;
            }
        }

        // Verificamos si se encontro la funcionalidad
        // si no se encontro quiere decir que estan agregando la funcionalidad al plan
        if (!funcionEncontrada) {
            const tmpFuncion = new FuncionalidadPlan();
            tmpFuncion.funcionalidad_modulo_id = funcion_id;
            tmpFuncion.plan_id = this.formPlan._id;

            // agregamos la funcionalidad a la lista
            this.funcionalidadesPlan.push(tmpFuncion);
        }
    }

    /**
     * Permite establecer el limite de una funcion
     * @param function_id 
     */
    public setLimitFunction(funcion_id: string) {

        // recorremos las funcionalidades agregadas previamente
        for (const funcion of this.funcionalidadesPlan) {

            // verificamos si es la funcion que estamos buscando
            if (funcion.funcionalidad_modulo_id == funcion_id) {

                // devolvemos la variable con la que trabajaremos
                return funcion;
            }
        }

        const tmpFunc = new FuncionalidadPlan();
        tmpFunc.funcionalidad_modulo_id = funcion_id;

        return tmpFunc;
    }

    /**
     * Permite guardar la informacion del formulario de planes
     */
    public save() {
        jQuery.fancybox.showLoading();

        // primero guardamos la informacion del plan
        this.planesService.save(this.formPlan)
            .subscribe((response) => {

                // en caso de que se este agregando un nuevo plan
                if (this.formPlan._id == null) {
                    // asignamos el valod del _ID
                    this.formPlan._id = response['_id'];
                }

                // guardamos las funcionalidades del plan
                this.funcionalidadesPlanService.setByPlanId(this.formPlan._id, this.funcionalidadesPlan)
                    .subscribe(() => {
                        this.router.navigate(['planes']);
                        jQuery.fancybox.hideLoading();
                    })
            });
    }



}
