<div class="content-wrapper">

    <div class="content-header row">
        <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">
                Empresas
            </h3>
            <div class="row breadcrumbs-top d-inline-block">
                <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/empresas">
                                Empresas
                            </a>
                        </li>
                        <li class="breadcrumb-item active">
                            Lista de empresas
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-header-right col-md-4 col-12">
            <div class="btn-group float-md-right">
                <button class="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="icon-settings mr-1"></i>
                    Opciones
                </button>
                <div class="dropdown-menu arrow">
                    <a class="dropdown-item" routerLink="/empresas/agregar">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        Registrar Empresa
                    </a>
                </div>
            </div>
        </div>
    </div>


    <div class="content-body">

        <!-- Basic Tables start -->
        <div class="row">

            <div class="col-12">

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">
                            Lista de Empresas
                        </h4>
                        <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                <li><a data-action="close"><i class="ft-x"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="card-content collapse show">

                        <div class="card-body">
                            <div class="row">
                                <div class="col-8">
                                    <p class="card-text">
                                        Aqui se muestra todos los registros de empresas que posees o a los que tienes acceso actualmente
                                    </p>
                                </div>
                                <div class="col-4">
                                    <div class="form-group position-relative has-icon-left m-0">
                                        <input type="search" class="form-control" id="iconLeft4" placeholder="Buscar" [(ngModel)]="search" name="search" >
                                        <div class="form-control-position">
                                            <i class="ft-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nit</th>
                                            <th>Razon Social</th>
                                            <th>Cuenta</th>
                                            <th>Alegra</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let empresa of empresas | gridFilter: search | paginate: { itemsPerPage: 10, currentPage: page }; let i = index;">
                                            <th scope="row">
                                                {{i+1}}
                                            </th>
                                            <td>
                                                {{empresa.nit}}
                                            </td>
                                            <td>
                                                {{empresa.razon_social}}
                                            </td>
                                            <td>
                                                {{getLabelCuenta(empresa.cuenta_id)}}
                                            </td>
                                            <td>
                                                <input  [disabled]="true" [checked]="empresa.alegra_id != null" name="alegra" type="checkbox" title="indica si esta vinculado con alegra">
                                            </td>
                                            <td>
                                                <div class="btn-group" role="group" >
                                                    <a class="btn btn-light " title="Modificar empresa" routerLink="/empresas/{{empresa._id}}/modificar"> 
                                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                    </a>
                                                    <a class="btn btn-light " title="Ver informacion de la empresa" routerLink="/empresas/{{empresa._id}}/detalles"> 
                                                        <i class="fa fa-search-plus" aria-hidden="true"></i>
                                                    </a>

                                                    <a class="btn btn-light " title="Porcentaje de implementacion" > 
                                                        {{getPorcentaje(empresa._id)}} %
                                                    </a>
                                                    
                                                    <button type="button" class="btn btn-info" *ngIf="empresa.alegra_id == null" title="vincular con alegra" (click)="linkCompanyToAlegra(empresa._id)">
                                                        <i class="fa fa-exchange" aria-hidden="true"></i>
                                                    </button>

                                                    <button type="button" class="btn btn-success" *ngIf="empresa.alegra_id != null" title="actualizar en alegra" (click)="updateCompanyToAlegra(empresa._id)">
                                                        <i class="fas fa-sync"></i>
                                                    </button>

                                                    <!-- <button type="button" class="btn btn-danger" (click)="remove(empresa._id, i)">
                                                        <i class="fa fa-trash"></i>
                                                    </button> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- Basic Tables end -->

    </div>


</div>