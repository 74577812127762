import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
    name: 'date'
})
export class DatePipe implements PipeTransform {
    transform(dateUnix: number | string, args?: any): any {
        return moment.unix(Number(dateUnix)).format(moment.HTML5_FMT.DATE);
    }
}
