import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ImplementacionEmpresaService = /** @class */ (function () {
    function ImplementacionEmpresaService(http) {
        this.http = http;
    }
    ImplementacionEmpresaService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl.admin + "/implementations");
    };
    ImplementacionEmpresaService.prototype.save = function (estado) {
        if (!estado._id) {
            return this.add(estado);
        }
        return this.edit(estado);
    };
    ImplementacionEmpresaService.prototype.add = function (estado) {
        delete estado._id;
        return this.http.post(environment.apiUrl.admin + "/implementations", estado);
    };
    ImplementacionEmpresaService.prototype.edit = function (estado) {
        return this.http.put(environment.apiUrl.admin + "/implementations/" + estado._id, estado);
    };
    ImplementacionEmpresaService.prototype.get = function (_id) {
        return this.http.get(environment.apiUrl.admin + "/implementations/" + _id);
    };
    ImplementacionEmpresaService.prototype.getByBusiness = function (_id) {
        return this.http.get(environment.apiUrl.admin + "/business/" + _id + "/implementations");
    };
    ImplementacionEmpresaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImplementacionEmpresaService_Factory() { return new ImplementacionEmpresaService(i0.ɵɵinject(i1.HttpClient)); }, token: ImplementacionEmpresaService, providedIn: "root" });
    return ImplementacionEmpresaService;
}());
export { ImplementacionEmpresaService };
