import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CuentaAdministrador } from 'src/app/entities/cuenta-administrador';
import { CuentasAdministradoresService } from 'src/app/services/cuentas-administradores.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

declare var jQuery;

@Component({
  selector: 'app-form-cuentas-admin',
  templateUrl: './form-cuentas-admin.component.html',
  styleUrls: ['./form-cuentas-admin.component.scss']
})
export class FormCuentasAdminComponent implements OnInit {

    public formData: CuentaAdministrador;

    constructor(
        private cuentasService: CuentasAdministradoresService,
        private currentRoute: ActivatedRoute,
        private router: Router,
        private location: Location
    ) {
        // inicializamos la variable
        this.formData = new CuentaAdministrador();


        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    ngOnInit() {
        // luego cargamos en ella la informaion del usuario
        this.formData = new CuentaAdministrador();
        jQuery.fancybox.hideLoading();
    }

    /**
     * Permite cargar la informacion de la cuenta a editar
     * @param _id 
     */
    private load(_id: string){
        jQuery.fancybox.showLoading();
        this.cuentasService.get(_id)
            .subscribe((response: CuentaAdministrador)=>{
                this.formData = response;
                jQuery.fancybox.hideLoading();
            })
    }


    /**
     * Permite guardar la informacion de la cuenta
     */
    save() {
        if(this.formData.password != this.formData.repassword){
            Swal({
                type: 'error',
                title: 'Oops...',
                text: "Las contraseñas no coinciden"
            });
            return;
        }else{
            jQuery.fancybox.showLoading();
            this.cuentasService.save(this.formData)
                .subscribe((response) => {

                    // actualizamos la informacion local
                    jQuery.fancybox.hideLoading();

                    Swal({
                        type: 'success',
                        title: 'Hecho',
                        text: response['message']
                    });

                    // this.router.navigate(['cuentas']);
                    this.location.back();

                }, (responseError)=>{
                    Swal({
                        type: 'error',
                        title: 'Oops...',
                        text: responseError.error['message']
                    });
                    jQuery.fancybox.hideLoading();
                });
        }
    }
}
