import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConexionesBdService = /** @class */ (function () {
    function ConexionesBdService(http) {
        this.http = http;
    }
    /**
     * Permite obtener un registro
     * @param _id
     */
    ConexionesBdService.prototype.getByEmpresa = function (_id) {
        return this.http.get(environment.apiUrl.admin + '/business/' + _id + '/connections-bd');
    };
    /**
     * Permite guardar los datos de un registro
     * @param formData
     */
    ConexionesBdService.prototype.save = function (formData) {
        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }
        return this.edit(formData);
    };
    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData
     */
    ConexionesBdService.prototype.add = function (formData, createdBd) {
        if (createdBd === void 0) { createdBd = false; }
        return this.http.post(environment.apiUrl.admin + '/connections-bd', {
            "conexionBd": formData,
            "createBd": createdBd
        });
    };
    /**
     * Permite guardar los cambios de un registro
     * @param formData
     */
    ConexionesBdService.prototype.edit = function (formData) {
        return this.http.put(environment.apiUrl.admin + '/connections-bd/' + formData._id, formData);
    };
    /**
     * Permite eliminar un plan
     * @param _id
     */
    ConexionesBdService.prototype.remove = function (_id) {
        return this.http.delete(environment.apiUrl.admin + '/connections-bd/' + _id);
    };
    ConexionesBdService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConexionesBdService_Factory() { return new ConexionesBdService(i0.ɵɵinject(i1.HttpClient)); }, token: ConexionesBdService, providedIn: "root" });
    return ConexionesBdService;
}());
export { ConexionesBdService };
