import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LicenciasService = /** @class */ (function () {
    function LicenciasService(http) {
        this.http = http;
    }
    /**
     * Permite obtener todos los registros
     */
    LicenciasService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl.admin + '/licenses');
    };
    /**
     * Permite obtener todos los registros
     */
    LicenciasService.prototype.getByCuenta = function (_id) {
        return this.http.get(environment.apiUrl.admin + '/accounts/' + _id + '/licenses');
    };
    /**
     * Permite obtener todos los registros
     */
    LicenciasService.prototype.getByEmpresa = function (_id) {
        return this.http.get(environment.apiUrl.admin + '/business/' + _id + '/licenses');
    };
    /**
     * Permite obtener un registro
     * @param _id
     */
    LicenciasService.prototype.get = function (_id) {
        return this.http.get(environment.apiUrl.admin + '/licenses/' + _id);
    };
    /**
     * Permite guardar los datos de un registro
     * @param formData
     */
    LicenciasService.prototype.save = function (formData) {
        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }
        return this.edit(formData);
    };
    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData
     */
    LicenciasService.prototype.add = function (formData) {
        return this.http.post(environment.apiUrl.admin + '/licenses', formData);
    };
    /**
     * Permite guardar los cambios de un registro de usuario
     * @param formData
     */
    LicenciasService.prototype.edit = function (formData) {
        return this.http.put(environment.apiUrl.admin + '/licenses/' + formData._id, formData);
    };
    /**
     * Permite eliminar un plan
     * @param _id
     */
    LicenciasService.prototype.remove = function (_id) {
        return this.http.delete(environment.apiUrl.admin + '/licenses/' + _id);
    };
    LicenciasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LicenciasService_Factory() { return new LicenciasService(i0.ɵɵinject(i1.HttpClient)); }, token: LicenciasService, providedIn: "root" });
    return LicenciasService;
}());
export { LicenciasService };
