<div class="row">
	<div class="col-12">
		<div class="card">
			<div class="card-header">
				<h4 class="card-title">
					Estado de implementacion del negocio
				</h4>
			</div>
			<div class="card-body">
				<div class="card-block">
					<h3 class="center-block">Estado de implementación {{getPorcentaje()}} %</h3>
					<ul class="nav nav-tabs  nav-justified  ">
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" id="configuracionLink" aria-control="configuracion" href="#configuracion">Configuración</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" id="AdministracionLink" aria-control="Administracion" href="#Administracion">Administración</a>
						</li>
					</ul>
					<div class="tab-content p-2" *ngIf="estado">
						<div class="tab-pane fade" aria- role="tabpanel" id="configuracion" aria-labelledby="configuracionLink">
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Empresas</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
										
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="ajustes" class="control-label mr-1">Ajustes</label>
														<input type="checkbox" id="ajustes" [(ngModel)]="estado.empresaConfiguracion.ajustes">
												</div>
												<div class="form-group col-sm-3">
													<label for="documentos" class="control-label mr-1">documentos</label>
													<input type="checkbox" id="documentos" [(ngModel)]="estado.empresaConfiguracion.documentos">
												</div>
												<div class="form-group col-sm-3">
													<label for="formasDePago" class="control-label mr-1">formas</label>
													<input type="checkbox" id="formasDePago" [(ngModel)]="estado.empresaConfiguracion.formasPago">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Empresas</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
										
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="usuarios" class="control-label mr-1">Crear Usuarios</label>
													<input type="checkbox" id="usuarios" [(ngModel)]="estado.usuariosConfiguracion.crear_usuarios">
												</div>
												<div class="form-group col-sm-3">
													<label for="roles" class="control-label mr-1">Roles</label>
													<input type="checkbox" id="roles" [(ngModel)]="estado.usuariosConfiguracion.roles">
												</div>
												<div class="form-group col-sm-4">
													<label for="permisos" class="control-label mr-1">Asignar roles y permisos</label>
													<input type="checkbox" id="permisos" [(ngModel)]="estado.usuariosConfiguracion.asignarPermisos">
												</div>
											</div>
										</div>
										
									</div>
								</div>
								
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Talento humano</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
										
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="cargos" class="control-label mr-1">Cargos</label>
													<input type="checkbox" id="cargos" [(ngModel)]="estado.nominaConfiguracion.cargos">
												</div>
												<div class="form-group col-sm-3">
													<label for="empleados" class="control-label mr-1">Empleados</label>
													<input type="checkbox" id="empleados" [(ngModel)]="estado.nominaConfiguracion.empleados">
												</div>
												<div class="form-group col-sm-3">
													<label for="tipo" class="control-label mr-1">Tipos de nomina</label>
													<input type="checkbox" id="tipo" [(ngModel)]="estado.nominaConfiguracion.tipos_nomina">
												</div>
												<div class="form-group col-sm-3">
													<label for="ajustesComi" class="control-label mr-1">Ajustes de comisión</label>
													<input type="checkbox" id="ajustesComi" [(ngModel)]="estado.nominaConfiguracion.comisiones">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Portafolio</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
										
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3 col-xs-6">
													<label for="categorias" class="control-label mr-1">Categorias</label>
													<input type="checkbox" id="categorias" [(ngModel)]="estado.portafolioConfiguracion.categorias">
												</div>
												<div class="form-group col-sm-3 col-xs-6">
													<label for="tipoUnidad" class="control-label mr-1">Tipos de unidades</label>
													<input type="checkbox" id="tipoUnidad" [(ngModel)]="estado.portafolioConfiguracion.tiposUnidades">
												</div>
												<div class="form-group col-sm-3 col-xs-6">
													<label for="impuestos" class="control-label mr-1">Impuestos</label>
													<input type="checkbox" id="impuestos" [(ngModel)]="estado.portafolioConfiguracion.impuestos">
												</div>
												<div class="form-group col-sm-3  col-xs-6">
													<label for="atributos" class="control-label mr-1">Atributos de producto</label>
													<input type="checkbox" id="atributos" [(ngModel)]="estado.portafolioConfiguracion.atributosProductos">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Facturación</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
										
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="pos" class="control-label mr-1">Caja POS</label>
													<input type="checkbox" id="pos" [(ngModel)]="estado.facturacionConfiguracion.cajaPos">
												</div>
												<div class="form-group col-sm-3">
													<label for="mediosPago" class="control-label mr-1">Medios de pago</label>
													<input type="checkbox" id="mediosPago" [(ngModel)]="estado.facturacionConfiguracion.mediosPago">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Gastos</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
										
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="categoriasGastos" class="control-label mr-1">Categorias</label>
													<input type="checkbox" id="categoriasGastos" [(ngModel)]="estado.gastosConfiguracion.categorias">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Tesorería</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="card-body row">
													<div class="form-group col-sm-3">
														<label for="cuentasBancAdm" class="control-label mr-1">Cuentas bancarias</label>
														<input type="checkbox" id="cuentasBancAdm" [(ngModel)]="estado.tesoreriaAdministracion.cuentasBancarias">
													</div>
													<div class="form-group col-sm-3">
														<label for="movNomAdm" class="control-label mr-1">Conceptos de conciliacion</label>
														<input type="checkbox" id="movNomAdm" [(ngModel)]="estado.tesoreriaAdministracion.conceptosConciliacion">
													</div>
													<div class="form-group col-sm-4">
														<label for="prestamosAdm" class="control-label mr-1">Movimientos de conciliacion</label>
														<input type="checkbox" id="prestamosAdm" [(ngModel)]="estado.tesoreriaAdministracion.movimientosConciliacion">
													</div>
												</div>	
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Facturación Electrónica</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
										
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="categoriasGastos" class="control-label mr-1">Categorias</label>
													<input type="checkbox" id="categoriasGastos" [(ngModel)]="estado.gastosConfiguracion.categorias">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>							
						</div>
						<div class="tab-pane fade" role="tabpanel" id="Administracion" aria-labelledby="AdministracionLink">
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Portafolio</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="serviAdm" class="control-label mr-1">Servicios</label>
													<input type="checkbox" id="serviAdm" [(ngModel)]="estado.portafolioAdministracion.servicios">
												</div>
												<div class="form-group col-sm-2">
													<label for="prodAdm" class="control-label mr-1">Productos</label>
													<input type="checkbox" id="prodAdm" [(ngModel)]="estado.portafolioAdministracion.productos">
												</div>
												<div class="form-group col-sm-3">
													<label for="invAdm" class="control-label mr-1">Inventario</label>
													<input type="checkbox" id="invAdm" [(ngModel)]="estado.portafolioAdministracion.inventario">
												</div>
												<div class="form-group col-sm-4">
													<label for="movAdm" class="control-label mr-1">Movimientos de inventario</label>
													<input type="checkbox" id="movAdm" [(ngModel)]="estado.portafolioAdministracion.movimientosInventario">
												</div>
											</div>
										</div>
										
									</div>
								</div>								
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Terceros</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="cliAdm" class="control-label mr-1">Clientes</label>
													<input type="checkbox" id="cliAdm" [(ngModel)]="estado.tercerosAdministracion.clientes">
												</div>
												<div class="form-group col-sm-3">
													<label for="provAdm" class="control-label mr-1">Proveedores</label>
													<input type="checkbox" id="provAdm" [(ngModel)]="estado.tercerosAdministracion.proveedores">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Talento humano</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="nomAdm" class="control-label mr-1">Nomina</label>
													<input type="checkbox" id="nomAdm" [(ngModel)]="estado.nominaAdministracion.nomina">
												</div>
												<div class="form-group col-sm-3">
													<label for="movNomAdm" class="control-label mr-1">Movimientos de nomina</label>
													<input type="checkbox" id="movNomAdm" [(ngModel)]="estado.nominaAdministracion.movimientosNomina">
												</div>
												<div class="form-group col-sm-3">
													<label for="prestamosAdm" class="control-label mr-1">Prestamos</label>
													<input type="checkbox" id="prestamosAdm" [(ngModel)]="estado.nominaAdministracion.prestamos">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Tesorería</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="cuentasBancAdm" class="control-label mr-1">Cuentas bancarias</label>
													<input type="checkbox" id="cuentasBancAdm" [(ngModel)]="estado.tesoreriaAdministracion.cuentasBancarias">
												</div>
												<div class="form-group col-sm-3">
													<label for="conConciliacion" class="control-label mr-1">Conceptos de conciliacion</label>
													<input type="checkbox" id="conConciliacion" [(ngModel)]="estado.tesoreriaAdministracion.conceptosConciliacion">
												</div>
												<div class="form-group col-sm-4">
													<label for="prestamosAdm" class="control-label mr-1">Movimientos de conciliacion</label>
													<input type="checkbox" id="prestamosAdm" [(ngModel)]="estado.tesoreriaAdministracion.movimientosConciliacion">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>

							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Compras</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="regCompAdm" class="control-label mr-1">Registrar Compra</label>
													<input type="checkbox" id="regCompAdm" [(ngModel)]="estado.comprasAdministracion.registrarCompra">
												</div>
												<div class="form-group col-sm-3">
													<label for="pagComAdm" class="control-label mr-1">Pagar Compras</label>
													<input type="checkbox" id="pagComAdm" [(ngModel)]="estado.comprasAdministracion.pagarCompra">
												</div>
												<div class="form-group col-sm-3">
													<label for="editCompAdm" class="control-label mr-1">Editar compras</label>
													<input type="checkbox" id="editCompAdm" [(ngModel)]="estado.comprasAdministracion.editarCompra">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Gastos</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="regGastAdm" class="control-label mr-1">Registrar gastos</label>
													<input type="checkbox" id="regGastAdm" [(ngModel)]="estado.gastosAdministracion.registrarGastos">
												</div>
												<div class="form-group col-sm-3">
													<label for="pagGastAdm" class="control-label mr-1">Pagar Gastos</label>
													<input type="checkbox" id="pagGastAdm" [(ngModel)]="estado.gastosAdministracion.pagarGastos">
												</div>
												<div class="form-group col-sm-3">
													<label for="editGastAdm" class="control-label mr-1">Editar Gastos</label>
													<input type="checkbox" id="editGastAdm" [(ngModel)]="estado.gastosAdministracion.editarGastos">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Facturación</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="doFactAdm" class="control-label mr-1">Realizar Facturas</label>
													<input type="checkbox" id="doFactAdm" [(ngModel)]="estado.facturacionAdministracion.realizarFacturas">
												</div>
												<div class="form-group col-sm-3">
													<label for="editFactAdm" class="control-label mr-1">Editar facturas</label>
													<input type="checkbox" id="editFactAdm" [(ngModel)]="estado.facturacionAdministracion.editarFacturas">
												</div>
												<div class="form-group col-sm-3">
													<label for="recaudoFacturasAdm" class="control-label mr-1">Recaudo de facturas</label>
													<input type="checkbox" id="recaudoFacturasAdm" [(ngModel)]="estado.facturacionAdministracion.recaudoFacturas">
												</div>
												<div class="form-group col-sm-3">
													<label for="cierreDeCaja" class="control-label mr-1">Cierres de caja</label>
													<input type="checkbox" id="cierreDeCaja" [(ngModel)]="estado.facturacionAdministracion.cierreCaja">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>

							<div class="row">
								<div class="card col-12 box-shadow-0 border-primary">
									<div class="row">
										<div class="card-header col-md-12 card-head-inverse bg-primary">
											<h4 class="card-title text-white">Facturación Electrónica</h4>
											<a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
						        			<div class="heading-elements">
												<ul class="list-inline mb-0">
							                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
							                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
							                        <li><a data-action="close"><i class="ft-x"></i></a></li>
							                    </ul>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="card-content col-12 collapse show">
											<div class="card-body row">
												<div class="form-group col-sm-3">
													<label for="doFactAdm" class="control-label mr-1">Realizar Facturas</label>
													<input type="checkbox" id="doFactAdm" [(ngModel)]="estado.facturacionAdministracion.realizarFacturas">
												</div>
												<div class="form-group col-sm-3">
													<label for="editFactAdm" class="control-label mr-1">Editar facturas</label>
													<input type="checkbox" id="editFactAdm" [(ngModel)]="estado.facturacionAdministracion.editarFacturas">
												</div>
												<div class="form-group col-sm-3">
													<label for="recaudoFacturasAdm" class="control-label mr-1">Recaudo de facturas</label>
													<input type="checkbox" id="recaudoFacturasAdm" [(ngModel)]="estado.facturacionAdministracion.recaudoFacturas">
												</div>
												<div class="form-group col-sm-3">
													<label for="cierreDeCaja" class="control-label mr-1">Cierres de caja</label>
													<input type="checkbox" id="cierreDeCaja" [(ngModel)]="estado.facturacionAdministracion.cierreCaja">
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							
						</div>
					</div>
					<div class="row p-2">
						<button class="btn btn-primary btn-block" (click)="enviar()">Guardar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- tabs de implementación -->
</div>