export class Filter {

    fecha_inicio: string;
    fecha_fin: string;
    search: string;

    constructor(){
        this.fecha_inicio = null;
        this.fecha_fin = null;
        this.search = null;
    }
}
