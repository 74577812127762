import { Component, OnInit } from '@angular/core';
import { ModulosService } from '../../services/modulos.service';
import { ActivatedRoute } from '@angular/router';
import { Modulo } from '../../entities/modulo';
import { FuncionalidadesModuloService } from '../../services/funcionalidades-modulo.service';
import { FuncionalidadModulo } from '../../entities/funcionalidad-modulo';
import swal from 'sweetalert2';
@Component({
  selector: 'app-view-modulos',
  templateUrl: './view-modulos.component.html',
  styleUrls: ['./view-modulos.component.scss']
})
export class ViewModulosComponent implements OnInit {
	public formData:Modulo;
	public funcionalidades:Array<FuncionalidadModulo>;
	constructor(private modulosService:ModulosService,private router:ActivatedRoute, public funcionalidadesService:FuncionalidadesModuloService){
		this.formData = new Modulo();
	}

	ngOnInit() {
		this.router.params.subscribe(params=>{
			if (typeof params.id != "undefined" ) {
				this.load(params.id);
				this.loadFunctionalities(params.id);
			}
		})
	}

	public load(id:String){
		this.modulosService.getById(id).subscribe((modulo:Modulo)=>{
			this.formData = modulo;
		});
	}
	public loadFunctionalities(id:String){
		this.funcionalidadesService.getByModule(id).subscribe((functionalities:Array<FuncionalidadModulo>)=>{
			this.funcionalidades = functionalities;
		});

	}

	public delete(id:String, i:number){
		swal({
			type:'question',
			title:'Está seguro que desea hacer esto?',
			text:'Si confirma este registro se perderá para siempre',
			showConfirmButton:true,
			showCancelButton:true
		}).then(val=>{
			if(val.value){
				this.funcionalidadesService.delete(id).subscribe(succ=>{
					this.funcionalidades.splice(i,1);
					swal({
						title:'Se eliminó el registro con exito',
						type:'success',
						showConfirmButton:false,
						timer:1000
					});
				});
			}
		})
	}
}
